
// "product" DCE
.single-product {
  position: relative;
  border-radius: 10px;

  .item {
    border: 2px solid $primary;
    display: block;
    background: #fff;
    text-decoration: none;
    border-radius: 10px;
    margin-bottom: 35px;
    overflow: hidden;
    transform: scale(1);

    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }

    @media (min-width: $screen-sm-min) {
      transition: transform 150ms ease-out;
      z-index: 1;
    }

    &:hover {
      @media (min-width: $screen-sm-min) {
        transform: scale(1.1);
        z-index: 100;
        box-shadow: 0px 7px 8px 0px rgba(0,0,0,0.2)
      }
    }

  }

  .image {
    position: relative;
    padding: 25px;
  }

  .image img {
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
    min-width: 100%;
  }

  .link {
    opacity: 0;
    background: $primary;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    padding: 15px;
    text-align: center;
    transition: opacity 250ms ease-out;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }


  .details {
    padding: 0 25px 25px 25px;
    color: #000;
    text-decoration: none;
    min-height: 180px;
    position: relative;
    background: rgb(238, 247, 255);

    .title {
      font-weight: 700;
      margin: 0;
      padding-top: 10px;
      font-size: 17px;
      padding-bottom: 3px;
    }


    .meta, .title {
      background: #F1F1F1;
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .meta {
      padding-bottom: 10px;
      color: #333;
    }

    .meta, .teaser {
      font-size: 13px;
    }

    .teaser {
      padding-bottom: 20px;
    }

    .previous {
      text-decoration: line-through;
      color: #ccc;
    }

    .price {
      position: absolute;
      bottom: 10px;
    }


    @media (max-width: $screen-xs-max) {
      padding: 13px;


      .meta, .title {
        background: #F1F1F1;
        margin-left: -13px;
        margin-right: -13px;
        padding-left: 13px;
        padding-right: 13px;
      }
      .title {
        font-size: 14px;
      }
      .teaser {
        font-size: 12px;
      }
      .teaser {
        font-size: 12px;
      }
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: $screen-sm-min) {
    a:hover .link {
      opacity: 1;
    }
  }
}


// product-detailview
#product {

  .needhelp {
    background: #fff;

    display: none;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
      display: block;
      margin-bottom: 25px;

      img {
        margin: 0 auto;
      }
    }
  }



  @media (max-width: $screen-xs-max) {
    .service .service-content {
      padding: 25px 0 45px;
    }
  }


  .overview {
    padding: 25px 50px;
    @media (max-width: $screen-xs-max) {
      padding: 25px 15px;
    }
    position: relative;


    .social {
      position: absolute;
      top: 5px;
      right: 20px;
      z-index: 601;

      a {
        display: inline-block;
        padding-bottom: 10px;
      }
    }

    .corner {
      left: 13px;
    }

    .image {
      .viewer {
        text-align: center;
        border: 1px solid #eee;

        img {
          margin: 0 auto;
          display: inline-block;
        }

        .item {
          padding: 40px;
        }

        .carousel-indicators {
          bottom: -60px;
          li {
            border-color: #DEDEDE;
            background: #DEDEDE;
          }
          .active {
            background: #8D8D8D;
          }

          @media (max-width: $screen-sm-max) {
            bottom: 8px;
          }

          @media (min-width: $screen-sm-min) {
            display: none;
          }
        }

      }

      .images {
        padding-top: 15px;
        @include clearfix();
      }
      .badges {
        position: relative;
        padding-top: 10px;
      }

    }

    .thumbnails {
      position: relative;
      .thumbnail {
        float: left;
        cursor: pointer;
        border-color: #eee;
        background: #fff;
        padding: 5px;
        display: block;

        margin-right: 9px;
        height: 72px;
        width: 72px;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          max-height: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%)
        }

        .glyphicon-plus {
          margin-top: 21px;
          margin-left: 22px;
        }
      }
      .active {
        border-color: $brand-primary;
      }
    }


    .title {
      font-weight: 700;
      font-size: 40px;
    }

    .meta {
      margin-bottom: 50px;
    }

    .desc {
      a {
        font-weight: 700;
      }
    }

    .tagline {
      font-weight: 700;
      margin-bottom: 20px;
    }

    .price {
      text-align: right;
      font-size: 17px;
      margin-bottom:15px;
    }

    .buttons {
      text-align: right;

      .btn-inverse {
        border-width: 2px;
      }
    }
  }

  .about {
    padding-top: 25px;

    .tabs {
      a {
        display: block;
        padding: 12px 0;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        background: $primary;
        color: #fff;
        line-height: 1.1;
      }
      a.active {
        background: $secondary;
        color: #000;
      }
      a:hover {
        text-decoration: none;
      }
      a, a:hover, a:active, a:focus {
        outline: 0;
      }


      .row {
        margin-left: -1px;
        margin-right: -1px;
      }
      .row > div {
        padding-left: 1px;
        padding-right: 1px;
      }
    }

    .tabs.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 54px;
      background: #fff;
      box-shadow: 0 0px 6px rgba(0,0,0,0.5);
      z-index: 80;
      max-width: 1280px;
      margin: 0 auto;
    }


    .about-content {
      background: $secondary;

      .about-padding {
        padding: 50px 100px;
        @media (max-width: $screen-xs-max) {
          padding: 13px 25px;
        }
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          padding: 25px 50px;
        }
      }

      .accordion-tab {
        display: none;
      }

      @media (max-width: $screen-xs-max) {
        .accordion-tab {
          display: block;
          font-size: 17px;
          padding: 16px 13px;
          color: #fff;
          background: $primary;
          // text-transform: uppercase;
          text-decoration: none;
          //font-weight: 700;
          cursor: pointer;
          margin-bottom: 1px;

          .caret {
            top: 5px;
          }
        }
        .accordion-tab:hover {
          text-decoration: none;
        }
        .accordion-tab.active {
          //background: #000;
        }
        .about-panel {
          display: none;
        }
        .about-panel.active {
          display: block;
        }
      }
      @media (min-width: $screen-sm-min) {
        .about-panel {
          display: block !important;
        }
      }
    }


    #related {
      background: $primary url('/design/img/intl/related-bg.jpg');
      background-size: cover;

      .more-supplies {
        font-weight: bold;
        color: #fff;
      }

      .product-blocks-items {
        background: transparent;
        padding: 0 !important;
      }

      h3, .h3 {
        color: #fff;
      }

      .disclaimer .star {
        color: #fff;
      }
      .disclaimer .text {
        color: #fff;
      }
    }

    #service {
      background: #fff;
    }

    #techdetails {
      background: #fff;

      .h4 {
        font-size: 17px;
        font-weight: bold;
        // text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: -5px;
      }

      table {
        width: 100%;

        @media (max-width: $screen-xs-max) {
          margin-left: 0px;
          border-spacing: 0px 0;
          margin-bottom: 25px;
        }
      }

      td, th {
        padding: 10px 25px;
        width: 50%;

        @media (max-width: $screen-xs-max) {
          padding: 8px 15px;
        }
      }

      thead {
        th {
          background: #5A5A5A;
          color: #fff;
        }
      }

      tbody {
        td {
          background: #D9E6F1;
        }
        tr:nth-child(odd) td {
          background: #ffffff;
        }
      }

      tfoot {
        td {

        }
      }


      ul.downloads {

        li {
          padding-bottom: 10px;
          line-height: 32px;
        }
        img {
          padding-right: 10px;
        }
        a {
          color: #000;
        }
        a:hover {
          text-decoration: none;
        }
      }


    }


  }


  .needhelp {
    padding-bottom: 15px;
    .buttons {
      text-align: center;
    }
    .buttons a {
      display: inline-block;
      line-height: 70px;
      height: 70px;
      width: 70px;
      background: $brand-primary;
      color: #fff;
      margin: 0 25px;
      text-align: center;
      border-radius: 50%;
      font-weight: bold;
    }
    .buttons img {
      width: 50px;
      height: 50px;
    }
  }

}


.infomessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  height: 100%;
  z-index: 200;

  @media (max-width: $screen-xs-max) {
   // position: fixed;
  }
}
.informessage-wrap {
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: $screen-xs-max) {
   // display: block;
  }
}

.infomessage-overlay {
  width: 60%;
  background: #fff !important;
  color: #000 !important;
  padding: 25px;
  margin: 2% auto 0;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);

  @media (max-width: $screen-xs-max) {
    width: 90%;
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    width: 75%;
  }
}

.infomessage-title {
  margin: 0 !important;
  font-size: 20px !important;
}
.infomessage-content {
  margin-top: 15px;
}

.infomessage-close {
  margin-top: -25px;
  margin-right: -25px;
  background: $brand-primary;
  color: #fff;
  height: 37px;
  width: 37px;
  font-size: 31px;
  line-height: 1;
  float: right;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
}


.jts {
  .infomessage-title {
    font-size: 25px !important;
  }
}


@media (max-width: $screen-xs-max) {
  body.modal-open {
    overflow: hidden;
    position:fixed;
    width: 100%;
  }
}

#merkliste {

  @media (min-width: $screen-md-min) {
    .modal-dialog {
      width: 900px;
    }
  }
  @media (max-width: $screen-xs-max) {
    .modal-dialog {
      margin: 0;
      height: 100%;
    }
  }


  .modal-header {
    background: #f2f2f2;
    border-bottom-color: #f2f2f2;

    .modal-title {
      margin: 0;
    }
  }

  .modal-body {
    padding: 0;

    .title {
      color: #000;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .modal-body-container {
    padding: 15px;
  }

  #merkliste_intro {
    background: #f2f2f2;


  }

  #merkliste_form {
    background: #f2f2f2;

    li {
      margin-bottom: 10px;
    }

    .visible-xs-block .btn {
      display: block;
      width: 80%;
      margin: 0 auto;

      &.close-form {
        margin-bottom: 15px;
      }
      &.submit-form {
        margin-bottom: 5px;
      }
    }
  }


  #merkliste_products {
    overflow: auto;

    min-height: 400px;
    max-height: 600px;

    @media (max-width: $screen-xs-max) {
      max-height: initial;
      min-height: initial;
      height: auto;
      overflow: initial;
    }

    .title {
      color: #a0a0a0 !important;
    }

    .item {
      height: 120px;

      @media (max-width: $screen-xs-max){
        margin-bottom: 15px;
        border-bottom: 1px solid #eee;
        height: auto;

        .image {
          padding-right: 0;
        }
      }

      .thumbnail {
        height: 100px;
        text-align: center;
        display: block;
        img {
          max-height: 85%;
          height: auto!important;
          width: auto!important;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }

      .h4 {
        margin: 0;
        font-size: 18px;
      }

      .amount-input {
        margin-bottom: 15px;

        @media (max-width: $screen-xs-max) {
          margin-top: 20px;
          width: 65%;
          float: left;
        }

        .product-amount {
          width: 45px;
          padding: 4px 0;
          color: $brand-primary;
          margin-right: 5px;
          text-align: center;
          outline: none;
        }
      }

      .remove-button {
        .btn {
          color: #fff;
          width: 100%;

          @media (max-width: $screen-xs-max) {
            width: auto;
            padding: 5px;
            height: 27px;
            line-height: 1;
          }

          &:hover {
            background: #fff;
            color: #DC0000;
            border: 1px solid $btn-default-bg;
          }
        }

        @media (max-width: $screen-xs-max) {
          padding-top: 22px;
          text-align: right;
        }
      }



      small {
        color: #aaa;
        font-size: 12px;
      }
    }
  }
}


#merkliste_products .corner {
  left: 14px;
  height: 45px;
  width: 45px;
  z-index: 100;
  .corner-text {
    font-size: 10px;
  }
}

.monacor #merkliste_products .corner{
  width: 40px;
  height: 40px;
  line-height: 30px;
  top: -1px;
  left: 12px;

  .corner-text {
    margin-top: -5px;
    margin-left: -1px;
  }
}
.img #merkliste_products .corner {
  width: 36px;
  height: 31px;
  line-height: 32px;
  top: -10px;
  left: 2px;
}
@import "../_fontawesome/font-awesome";

.shariff::after, .shariff::before {
  content: " ";
  display: table
}

.shariff::after {
  clear: both
}

.shariff ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.shariff li {
  height: 35px;
  box-sizing: border-box;
  overflow: hidden
}

.shariff li a {
  color: #fff;
  position: relative;
  display: block;
  height: 35px;
  text-decoration: none;
  box-sizing: border-box
}

.shariff li .share_count, .shariff li .share_text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  vertical-align: middle;
  line-height: 35px
}

.shariff li .fa {
  width: 35px;
  line-height: 35px;
  text-align: center;
  vertical-align: middle
}

.shariff li .share_count {
  padding: 0 8px;
  height: 33px;
  position: absolute;
  top: 1px;
  right: 1px
}

.shariff .orientation-horizontal {
  display: -webkit-box
}

.shariff .orientation-horizontal li {
  -webkit-box-flex: 1
}

.shariff .orientation-horizontal .info {
  -webkit-box-flex: 0
}

.shariff .orientation-horizontal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.shariff .orientation-horizontal li {
  float: left;
  -ms-flex: none;
  flex: none;
  width: 35px;
  margin-right: 3%;
  margin-bottom: 10px
}

.shariff .orientation-horizontal li:last-child {
  margin-right: 0
}

.shariff .orientation-horizontal li .share_text {
  display: block;
  text-indent: -9999px;
  padding-left: 3px
}

.shariff .orientation-horizontal li .share_count {
  display: none
}

.shariff .theme-grey .shariff-button a {
  background-color: #b0b0b0
}

.shariff .theme-grey .shariff-button .share_count {
  background-color: #ccc;
  color: #333
}

.shariff .theme-white .shariff-button {
  border: 1px solid #ddd
}

.shariff .theme-white .shariff-button a {
  background-color: #fff
}

.shariff .theme-white .shariff-button a:hover {
  background-color: #eee
}

.shariff .theme-white .shariff-button .share_count {
  background-color: #fff;
  color: #999
}

.shariff .orientation-vertical {
  min-width: 110px
}

.shariff .orientation-vertical li {
  display: block;
  width: 100%;
  margin: 5px 0
}

.shariff .orientation-vertical li .share_count {
  width: 24px;
  text-align: right
}

@media only screen and (min-width: 360px) {
  .shariff .orientation-horizontal li {
    margin-right: 1.8%;
    min-width: 80px;
    width: auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
  }

  .shariff .orientation-horizontal li .share_count {
    display: block
  }

  .shariff .orientation-horizontal.col-1 li, .shariff .orientation-horizontal.col-2 li {
    min-width: 110px;
    max-width: 160px
  }

  .shariff .orientation-horizontal.col-1 li .share_text, .shariff .orientation-horizontal.col-2 li .share_text {
    text-indent: 0;
    display: inline
  }

  .shariff .orientation-horizontal.col-5 li, .shariff .orientation-horizontal.col-6 li {
    -ms-flex: none;
    flex: none
  }
}

@media only screen and (min-width: 640px) {
  .shariff .orientation-horizontal.col-3 li {
    min-width: 110px;
    max-width: 160px
  }

  .shariff .orientation-horizontal.col-3 li .share_text {
    text-indent: 0;
    display: inline
  }
}

@media only screen and (min-width: 768px) {
  .shariff .orientation-horizontal li {
    min-width: 110px;
    max-width: 160px
  }

  .shariff .orientation-horizontal li .share_text {
    text-indent: 0;
    display: inline
  }

  .shariff .orientation-horizontal.col-5 li, .shariff .orientation-horizontal.col-6 li {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
  }
}

@media only screen and (min-width: 1024px) {
  .shariff li {
    height: 30px
  }

  .shariff li a {
    height: 30px
  }

  .shariff li .fa {
    width: 30px;
    line-height: 30px
  }

  .shariff li .share_count, .shariff li .share_text {
    line-height: 30px
  }

  .shariff li .share_count {
    height: 28px
  }
}

.shariff .addthis a {
  background-color: #f8694d
}

.shariff .addthis a:hover {
  background-color: #f75b44
}

.shariff .addthis .fa-plus {
  font-size: 14px
}

.shariff .addthis .share_count {
  color: #f8694d;
  background-color: #f1b8b0
}

.shariff .theme-white .addthis a {
  color: #f8694d
}

@media only screen and (min-width: 600px) {
  .shariff .addthis .fa-plus {
    font-size: 14px;
    position: relative;
    top: 1px
  }
}

.shariff .diaspora a {
  background-color: #999
}

.shariff .diaspora a:hover {
  background-color: #b3b3b3
}

.shariff .diaspora .fa-times-circle {
  font-size: 17px
}

.shariff .theme-white .diaspora a {
  color: #999
}

@media only screen and (min-width: 600px) {
  .shariff .diaspora .fa-times-circle {
    font-size: 16px
  }
}

.shariff .facebook a {
  background-color: #3b5998
}

.shariff .facebook a:hover {
  background-color: #4273c8
}

.shariff .facebook .fa-facebook {
  font-size: 22px
}

.shariff .facebook .share_count {
  color: #183a75;
  background-color: #99adcf
}

.shariff .theme-white .facebook a {
  color: #3b5998
}

@media only screen and (min-width: 600px) {
  .shariff .facebook .fa-facebook {
    font-size: 19px
  }
}

.shariff .flattr a {
  background-color: #7ea352
}

.shariff .flattr a:hover {
  background-color: #F67C1A
}

.shariff .flattr a:hover .share_count {
  color: #d56308;
  background-color: #fab47c
}

.shariff .flattr .fa-money {
  font-size: 22px
}

.shariff .flattr .share_count {
  color: #648141;
  background-color: #b0c893
}

.shariff .theme-white .flattr a {
  color: #F67C1A
}

@media only screen and (min-width: 600px) {
  .shariff .flattr .fa-money {
    font-size: 19px
  }
}

.shariff .googleplus a {
  background-color: #d34836
}

.shariff .googleplus a:hover {
  background-color: #f75b44
}

.shariff .googleplus .fa-google-plus {
  font-size: 22px
}

.shariff .googleplus .share_count {
  color: #a31601;
  background-color: #eda79d
}

.shariff .theme-white .googleplus a {
  color: #d34836
}

@media only screen and (min-width: 600px) {
  .shariff .googleplus .fa-google-plus {
    font-size: 19px
  }
}

.shariff .info {
  border: 1px solid #ccc
}

.shariff .info a {
  color: #666;
  background-color: #fff
}

.shariff .info a:hover {
  background-color: #efefef
}

.shariff .info .fa-info {
  font-size: 20px;
  width: 33px
}

.shariff .info .share_text {
  display: block !important;
  text-indent: -9999px !important
}

.shariff .theme-grey .info a {
  background-color: #fff
}

.shariff .theme-grey .info a:hover {
  background-color: #efefef
}

.shariff .orientation-vertical .info {
  width: 35px;
  float: right
}

@media only screen and (min-width: 360px) {
  .shariff .orientation-horizontal .info {
    -ms-flex: none !important;
    flex: none !important;
    width: 35px;
    min-width: 35px !important
  }
}

@media only screen and (min-width: 1024px) {
  .shariff .info .fa-info {
    font-size: 16px;
    width: 23px
  }

  .shariff .orientation-horizontal .info {
    width: 25px;
    min-width: 25px !important
  }

  .shariff .orientation-vertical .info {
    width: 25px
  }
}

.shariff .linkedin a {
  background-color: #0077b5
}

.shariff .linkedin a:hover {
  background-color: #0369a0
}

.shariff .linkedin .fa-linkedin {
  font-size: 22px
}

.shariff .linkedin .share_count {
  color: #004785;
  background-color: #33AAE8
}

.shariff .theme-white .linkedin a {
  color: #0077b5
}

@media only screen and (min-width: 600px) {
  .shariff .linkedin .fa-linkedin {
    font-size: 19px
  }
}

.shariff .mail a {
  background-color: #999
}

.shariff .mail a:hover {
  background-color: #a8a8a8
}

.shariff .mail .fa-envelope {
  font-size: 21px
}

.shariff .theme-white .mail a {
  color: #999
}

@media only screen and (min-width: 600px) {
  .shariff .mail .fa-envelope {
    font-size: 18px
  }
}

.shariff .pinterest a {
  background-color: #bd081c
}

.shariff .pinterest a:hover {
  background-color: #d50920
}

.shariff .pinterest .fa-pinterest-p {
  font-size: 22px
}

.shariff .pinterest .share_count {
  color: #a31601;
  background-color: #eda79d
}

.shariff .theme-white .pinterest a {
  color: #bd081c
}

@media only screen and (min-width: 600px) {
  .shariff .pinterest .fa-pinterest-p {
    font-size: 19px;
    position: relative;
    top: 1px
  }
}

.shariff .reddit a {
  background-color: #ff4500
}

.shariff .reddit a:hover {
  background-color: #ff6a33
}

.shariff .reddit .fa-reddit {
  font-size: 17px
}

.shariff .theme-white .reddit a {
  color: #ff4500
}

@media only screen and (min-width: 600px) {
  .shariff .reddit .fa-reddit {
    font-size: 16px
  }
}

.shariff .stumbleupon a {
  background-color: #eb4924
}

.shariff .stumbleupon a:hover {
  background-color: #ef7053
}

.shariff .stumbleupon .fa-stumbleupon {
  font-size: 17px
}

.shariff .theme-white .stumbleupon a {
  color: #eb4924
}

@media only screen and (min-width: 600px) {
  .shariff .stumbleupon .fa-stumbleupon {
    font-size: 16px
  }
}

.shariff .twitter a {
  background-color: #55acee
}

.shariff .twitter a:hover {
  background-color: #32bbf5
}

.shariff .twitter .fa-twitter {
  font-size: 28px
}

.shariff .twitter .share_count {
  color: #0174a4;
  background-color: #96D4EE
}

.shariff .theme-white .twitter a {
  color: #55acee
}

@media only screen and (min-width: 600px) {
  .shariff .twitter .fa-twitter {
    font-size: 24px
  }
}

.shariff .whatsapp a {
  background-color: #5cbe4a
}

.shariff .whatsapp a:hover {
  background-color: #34af23
}

.shariff .whatsapp .fa-whatsapp {
  font-size: 28px
}

.shariff .theme-white .whatsapp a {
  color: #5cbe4a
}

@media only screen and (min-width: 600px) {
  .shariff .whatsapp .fa-whatsapp {
    font-size: 22px
  }
}

.shariff .xing a {
  background-color: #126567
}

.shariff .xing a:hover {
  background-color: #29888a
}

.shariff .xing .fa-xing {
  font-size: 22px
}

.shariff .xing .share_count {
  color: #15686a;
  background-color: #4fa5a7
}

.shariff .theme-white .xing a {
  color: #126567
}

@media only screen and (min-width: 600px) {
  .shariff .xing .fa-xing {
    font-size: 19px
  }
}

.shariff .tumblr a {
  background-color: #36465D
}

.shariff .tumblr a:hover {
  background-color: #44546B
}

.shariff .tumblr .fa-tumblr {
  font-size: 28px
}

.shariff .theme-white .tumblr a {
  color: #5cbe4a
}

@media only screen and (min-width: 600px) {
  .shariff .tumblr .fa-tumblr {
    font-size: 22px
  }
}

.shariff .threema a {
  background-color: #333
}

.shariff .threema a:hover {
  background-color: #1f1f1f
}

.shariff .threema .fa-lock {
  font-size: 28px
}

.shariff .theme-white .threema a {
  color: #333
}

@media only screen and (min-width: 600px) {
  .shariff .threema .fa-lock {
    font-size: 22px
  }
}

.shariff .weibo a {
  background-color: #F56770
}

.shariff .weibo a:hover {
  background-color: #FA7F8A
}

.shariff .weibo .fa-weibo {
  font-size: 28px
}

.shariff .weibo .share_count {
  color: #0174a4;
  background-color: #F56770
}

.shariff .theme-white .weibo a {
  color: #F56770
}

@media only screen and (min-width: 600px) {
  .shariff .weibo .fa-weibo {
    font-size: 24px
  }
}

.shariff .tencent-weibo a {
  background-color: #26ACE0
}

.shariff .tencent-weibo a:hover {
  background-color: #38BBEB
}

.shariff .tencent-weibo .fa-tencent-weibo {
  font-size: 28px
}

.shariff .tencent-weibo .share_count {
  color: #0174a4;
  background-color: #26ACE0
}

.shariff .theme-white .tencent-weibo a {
  color: #26ACE0
}

@media only screen and (min-width: 600px) {
  .shariff .tencent-weibo .fa-tencent-weibo {
    font-size: 24px
  }
}

.shariff .qzone a {
  background-color: #2B82D9
}

.shariff .qzone a:hover {
  background-color: #398FE6
}

.shariff .qzone .fa-qq {
  font-size: 28px
}

.shariff .qzone .share_count {
  color: #0174a4;
  background-color: #2B82D9
}

.shariff .theme-white .qzone a {
  color: #2B82D9
}

@media only screen and (min-width: 600px) {
  .shariff .qzone .fa-qq {
    font-size: 24px
  }
}
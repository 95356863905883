/*DCE: intro-new.html*/
.intro._seo {

	.intro-inner:after, .intro-inner:before {
		content: '';
		display: block;
		clear: both;
	}

	.intro-inner {
		height: 340px;
	}

	.intro-content-wrap, .intro-image-wrap {
		float: left;
		overflow: hidden;
		box-shadow: 0 4px 16px rgba(0, 1, 0, 0.2);
	}

	.intro-content-wrap {
		height: 340px;
		border-radius: 20px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		width: calc(100% - 120px - 120px - 350px);

		.intro-content {
			position: relative;
			bottom: 0;
			left: 0px;
			width: auto;
			padding: 80px 92px 80px 45px;
			background: $primary;
			color: #FFFFFF;
			height: 340px;
			border-radius: 0;
			a {
				color: inherit;
			}
		}
	}

	.intro-image-wrap {
		right: 0;
		padding: 0;
		width: 350px;
		border-radius: 20px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		left: initial;
		bottom: 0;
		position: relative;
		height: 340px;
		margin-left: 120px;

		.intro-image {
			top: 0;
			left: 0;
			width: 100%;
			background-position: 50% 50%;
			background-size: contain;
			background-repeat: no-repeat;
			position: relative;
			height: 260px;
			line-height: 260px;
		}

		.intro-meta {
			bottom: 0;
			right: 0;
			width: 320px;
			padding: 0 20px 20px;
			position: relative;
			border-top-left-radius: 0;
			background: transparent;
			color: $black;
			height: 80px;
			strong:first-child {
				+ span {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	.intro._seo .intro-image-wrap {
		margin-left: 25px;
		width: 330px;
	}

	.intro._seo .intro-content-wrap {
		width: calc(100% - 50px - 330px);
	}
}

@media screen and (max-width: 991px) {
	.intro._seo .intro-inner {
		height: 400px;
	}

	.intro._seo .intro-image-wrap {
		height: 400px;
	}

	.intro._seo .intro-content-wrap {
		height: 400px;
		.intro-content {
			height: 400px;
		}
	}
}

@media screen and (max-width: 767px) {
	.intro._seo .intro-inner {
		height: auto;
	}

	.intro._seo .intro-image-wrap {
		height: auto;
		width: calc(100% - 30px);
		border-radius: 20px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		margin-left: 15px;
	}

	.intro._seo .intro-content-wrap {
		width: calc(100% - 30px);
		margin-left: 25px;
		height: auto;
		border-radius: 20px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		margin-left: 15px;
		.intro-content {
			height: auto;
		}
	}
}

@media screen and (max-width: 480px) {
}

/*DCE: support_new.html*/
.support._seo {
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		padding-left: 100px;
		padding-right: 100px;
	}
	@media (max-width: 767px) {
		h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
			position: relative;
			padding-right: 28px;
			font-size: 16px;
		}
	}
	@media (max-width: 1200px) {
		h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
			padding-left: 0;
		}
	}
}

@media screen and (min-width: 1200px) {
	.support._seo {
		.support-content {

		}
		.support-image {

		}
		.support-contact {
			padding-right: 100px;
			max-width: 360px;
			width: 360px;
		}
	}
}

/*DCE: textblock_video_headline.html*/
.seo-video {
	.section-title {
		padding-left: 100px;
		padding-right: 100px;
	}
	.article {
		padding-left: 100px;
		padding-right: 100px;
	}
	.video {
		iframe {
			width: 100%;
		}
	}
}

@media screen and (max-width: 1199px) {
	.seo-video {
		.section-title {
			padding-left: 0;
			padding-right: 0;
		}
		.article {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 480px) {
}
.txtfett {
  font-weight: bold;
}
.tg {
  margin-bottom: 15px;
}
.tge {
  margin-bottom: 15px;
}
.tge.inline {
  display: inline;
  margin-bottom: 0;
}
.tg ul, .tg ol {
  padding-left: 19px;
}

ul.dashed > li {
  list-style-type: none;
}
ul.dashed > li:before{
  content: "-";
  position: relative;
  margin-left: -5px; /* optional, for multiline li element */
  left: -12px;
}

.tg ul ul, .tg ol ol {
  padding-left: 25px;
}

.txtdeinmitstr {
  padding-left: 20px;
}
.txtdpeinmitpkt {
  padding-left: 20px;
}
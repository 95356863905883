/*
	Table of Contents

	I.   Generic --------------------------------------
	     01. Reset ------------------------------------
	     02. Base -------------------------------------
	     03. Fonts ------------------------------------
	     04. Helpers ----------------------------------
	         Clear ------------------------------------
	         Notext -----------------------------------
	         Hidden -----------------------------------
	         Alignleft --------------------------------
	         Alignright -------------------------------
	         Disabled ---------------------------------
	         Grid -------------------------------------
	         Block Grid -------------------------------
	         Responsive Helpers -----------------------
	         Actions ----------------------------------
	II.  Regions --------------------------------------
	     01. Container --------------------------------
	     02. Content ----------------------------------
	     03. Footer -----------------------------------
	     04. Header -----------------------------------
	     05. Main -------------------------------------
	     06. Shell ------------------------------------
	     07. Sidebar ----------------------------------
	     08. Wrapper ----------------------------------
	III. Modules --------------------------------------
	     01. Article ----------------------------------
	     02. Button -----------------------------------
	         Button Block -----------------------------
	     03. Button Play ------------------------------
	     04. Form Elements ----------------------------
	         Form Row ---------------------------------
	         Field ------------------------------------
	     05. Form Subscribe ---------------------------
	     06. Image Box --------------------------------
	     07. Intro ------------------------------------
	         Intro Home -------------------------------
	     08. List -------------------------------------
	     09. list Contacts ----------------------------
	     10. List Services ----------------------------
	     11. Logo -------------------------------------
	     12. Nav --------------------------------------
	     13. News Box ---------------------------------
	     14. News Special -----------------------------
	     15. Product ----------------------------------
	     16. Quote ------------------------------------
	     17. Section ----------------------------------
	         Section Text -----------------------------
	         Section Services -------------------------
	         Section Fundament ------------------------
	         Section Fundament ------------------------
	         Section Products -------------------------
	     18. Section Subscribe ------------------------
	     19. Slider -----------------------------------
	     20. Slider Home ------------------------------
	     21. Support Item -----------------------------
	     22. Table ------------------------------------
	     23. Video ------------------------------------
	     24. Widget -----------------------------------
 */

.ico-envelope { background-image: url(/design/img/intl/sprite.png); background-position: -17px 0; width: 14px; height: 11px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-phone { background-image: url(/design/img/intl/sprite.png); background-position: 0 0; width: 13px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .ico-envelope { background-image: url(/design/img/intl/sprite.@2x.png); background-position: -15px 0; background-size: 29px 16px; width: 14px; height: 11px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-phone { background-image: url(/design/img/intl/sprite.@2x.png); background-position: 0 0; background-size: 29px 16px; width: 13px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }
}
.cols:after,
.article:after,
.list-services:after,
.products:after,
.section-products .section-body:after,
.section-subscribe .section-body:after { content: ''; line-height: 0; display: table; clear: both; }


/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after { content: ''; line-height: 0; display: table; clear: both; }
/*  Notext  */
.notext { white-space: nowrap; text-indent: 100%; text-shadow: none; overflow: hidden; }
.notext-old { font-size: 0; line-height: 0; text-indent: -4000px; }
/*  Hidden  */
[hidden],
.hidden { display: none; }
/*  Alignleft  */
.alignleft { float: left; }
/*  Alignright  */
.alignright { float: right; }
/*  Disabled  */
[disabled],
.disabled { cursor: default; }
/*  Grid  */
.cols { margin: 0 -15px; }
.cols:after { }
.cols-no-gutter { margin: 0; }
.cols-no-gutter .col { padding: 0; }
.col { float: left; width: 100%; padding: 0 15px; }
.col-1of2 { width: 50%; }
.col-1of3 { width: 33.33%; }
.col-2of3 { width: 66.66%; }
@media (max-width: 767px) {
  .col { width: 100%; }
}
/*  Block Grid  */
.block-grid { margin: -10px; font-size: 0; }
.block-grid-item { display: inline-block; width: 100%; vertical-align: top; padding: 10px; font-size: 16px;}
.block-grid-2 .block-grid-item { width: 50%; }
@media (max-width: 767px) {
  .block-grid-2 .block-grid-item { width: 100%; }
}
/*  Responsive Helpers  */
.visible-xs,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline { display: none; }
@media (max-width: 767px) {
  .hidden-xs { display: none; }
  .visible-xs { display: block; }
  .visible-xs-inline { display: inline; }
  .visible-xs-inline-block { display: inline-block; }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm { display: none; }
  .visible-sm-block { display: block; }
  .visible-sm-inline { display: inline; }
  .visible-sm-inline-block { display: inline-block; }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .hidden-md { display: none; }
  .visible-md-block { display: block; }
  .visible-md-inline { display: inline; }
  .visible-md-inline-block { display: inline-block; }
}
@media (min-width: 1201px) {
  .hidden-lg { display: none; }
  .visible-lg-block { display: block; }
  .visible-lg-inline { display: inline; }
  .visible-lg-inline-block { display: inline-block; }
}
/*  Actions  */
.actions { text-align: right; font-weight: bold; color: #00549c; }
/* ------------------------------------------------------------ *\
	II.  Regions
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */
.container {}
/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */
.content {}
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer {}
/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header {}
/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
.main {}
/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */
.shell { max-width: 1280px; padding-left: 25px; padding-right: 25px; margin: auto; }
.shell-no-gutter { padding-left: 0; padding-right: 0; }
@media (max-width: 767px) {
  .shell { padding-left: 15px; padding-right: 15px;  }

  .shell-no-gutter { padding-left: 0; padding-right: 0; }
}
/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */
.sidebar {}
/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper { position: relative; overflow: hidden; }
/* ------------------------------------------------------------ *\
	III. Modules
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */
.article:after { }
.article .article-content { float: left; width: 60%; padding-right: 25px; }
.article .article-aside { float: right; width: 40%; }
.article .article-actions { text-align: right; font-weight: bold; color: #00549c; }
@media (max-width: 767px) {
  .article .article-content,
  .article .article-aside { float: none; width: auto; }

  .article .article-content { padding-right: 0; }
  .article .article-content + .article-aside { padding-top: 25px; }
  .article .article-media { margin: 0 -15px; }
}
/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn { display: inline-block; vertical-align: middle; padding: 0 25px; border: 0; border-radius: 7px; background: #00549c; font-size: 18px; color: #fff; line-height: 39px; text-align: center; cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn:hover { background: rgb(25, 101, 166); text-decoration: none; }
/*  Button Block  */
.btn-block { display: block; padding-left: 0; padding-right: 0; }
/* ------------------------------------------------------------ *\
	Button Play
\* ------------------------------------------------------------ */
.btn-play { position: relative; display: inline-block; vertical-align: middle; width: 90px; height: 90px; border-radius: 50%; background: rgba(0, 84, 156, 0.8); font-size: 0; color: #fff; }
.btn-play:before { content: ''; position: absolute; top: 26px; left: 33px; width: 0; height: 0; border-style: solid; border-width: 19px 0 19px 33px; border-top-color: transparent; border-bottom-color: transparent; }
@media (max-width: 767px) {
  .btn-play { width: 52px; height: 52px; }
  .btn-play:before { top: 15px; left: 19px; border-width: 11px 0 11px 19px; }
}
/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */
input::-webkit-input-placeholder { color: inherit; opacity: 1; }
input::-moz-placeholder { color: inherit; opacity: 1; }
input:-ms-input-placeholder { color: inherit; opacity: 1; }
input::placeholder { color: inherit; opacity: 1; }
textarea::-webkit-input-placeholder { color: inherit; opacity: 1; }
textarea::-moz-placeholder { color: inherit; opacity: 1; }
textarea:-ms-input-placeholder { color: inherit; opacity: 1; }
textarea::placeholder { color: inherit; opacity: 1; }
input:-webkit-autofill { -webkit-text-fill-color: inherit !important; -webkit-box-shadow: 0 0 0 1000px #fff inset; }
/* Form Row */
.form-row { margin-bottom: 12px; }
/* Field */
.field { display: block; width: 100%; height: 36px; padding: 0 13px; border: 2px solid #e3e3e3; border-radius: 3px; }
/* ------------------------------------------------------------ *\
	Form Subscribe
\* ------------------------------------------------------------ */
.form-subscribe .form-body { padding-bottom: 10px; }
.form-subscribe .form-actions { text-align: right; }
/* ------------------------------------------------------------ *\
	Image Box
\* ------------------------------------------------------------ */
.image-box { position: relative; }
.image-box img { display: block; width: 100%; }
.image-box .image-caption { position: absolute; bottom: 0; left: 0; width: 100%; padding: 18px 25px; background: rgba(0,0,0, .8); color: #fff; }
.image-box .image-caption strong { display: block; }
.image-box .image-caption span { display: block; }
.image-box .visible-xs { display: none; }
@media (max-width: 767px) {
  .image-box .image-caption { position: static; padding: 10px 15px; }

  .image-box .visible-xs { display: block; }
  .image-box .hidden-xs { display: none; }
}
/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */
.intro .intro-inner { position: relative; height: 480px; }
.intro .intro-image { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-position: 50% 50%; background-size: cover; }
.intro .intro-content { position: absolute; bottom: 0; left: -20px; width: 50%; padding: 20px 92px 38px 45px; border-radius: 20px; border-bottom-right-radius: 0; background: rgba(0, 84, 156, 0.8); color: #fff; }
.intro .intro-title { color: #fff; margin-bottom: 15px; line-height: 1.22; margin-top:0 }
.intro .intro-meta { position: absolute; bottom: 0; right: 0; width: 320px; border-top-left-radius: 18px; padding: 18px 40px; background: rgba(0,0,0, .8); color: #fff; }
.intro .intro-meta strong { display: block; }
.intro .intro-meta span { display: block; }
/* Intro Home */
.intro-home .intro-inner { height: 545px; }
.intro-home .intro-content { padding-right: 30px; background: rgba(1, 41, 75, 0.85); }
@media (max-width: 1023px) {
  .intro .intro-image-mobile-center { background-position: right center; }

  .intro .intro-content { width: 60%; padding-right: 50px; }
}
@media (max-width: 767px) {
  .intro .intro-title { font-size: 18px; }
  .intro .intro-inner { height: auto; }
  .intro .intro-image { position: relative; height: 0; padding-top: 37%; }
  .intro .intro-content { position: static; width: 100%; padding: 23px 15px; border-radius: 0; background: #00549c; }
  .intro .intro-meta { width: auto; padding: 15px 30px; }
}
/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */
[class^="list-"] { list-style: none outside none; }
/* ------------------------------------------------------------ *\
	list Contacts
\* ------------------------------------------------------------ */
.list-contacts { font-size: 14px; }
.list-contacts li + li { padding-top: 3px; }
.list-contacts a { text-decoration: underline; }
.list-contacts i { margin-right: 5px; }
.list-contacts a:hover { text-decoration: none; }
/* ------------------------------------------------------------ *\
	List Services
\* ------------------------------------------------------------ */
.list-services { padding: 0 50px; list-style: none outside none; }
.list-services:after { }
.list-services li { float: left; width: 33.33%; padding: 0 30px; }
.list-services .h5 {  color: #00549c; font-weight: bold; margin-bottom: 0; min-height: 3.2em; }
@media (max-width: 1023px) {
  .list-services { padding: 0; }
  .list-services li { padding: 0 15px; }
}
@media (max-width: 767px) {
  .list-services { margin: 0 -15px; }
  .list-services li { width: 100%; }
}
/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
.logo {}
/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
.nav {}
/* ------------------------------------------------------------ *\
	News Box
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	News Special
\* ------------------------------------------------------------ */
.news-special {  }
.news-special .news-inner { position: relative; background: #00549c; color: #fff; }
.news-special .news-image { position: absolute; top: 0; right: 0; width: 50%; height: 100%; background-position: 50% 50%; background-size: cover; }
.news-special .news-content { position: relative; width: 50%; padding: 48px 120px 50px 140px; }
.news-special .news-date { position: absolute; bottom: 0; right: 0; width: 120px; padding: 7px 20px 8px; border-top-left-radius: 5px; background: rgba(0,0,0, .5); color: #fff; font-weight: bold; }
.news-special .news-title { margin-bottom: 28px; color: #fff }
.news-special .news-entry { min-height: 192px; }
.news-special .news-actions { position: absolute; bottom: 17px; left: 0; width: 100%; padding: 0 53px; text-align: right; }
.news-special .news-actions .link-more { font-weight: bold; }
@media (max-width: 1200px) {
  .news-special .news-content { padding: 48px 50px 50px; }
}
@media (max-width: 767px) {
  .news-special .news-image { position: relative; width: auto; padding-top: 50%; }
  .news-special .news-content { position: static; width: auto; padding: 15px; }

  .news-special .news-actions { position: static; padding: 0; }

  .news-special .news-entry { padding-bottom: 10px; min-height: 0; }
}
/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */
.products:after { }
.product { float: left; width: 33.33%; }
.product:first-child .product-content { padding-left: 35px; }
.product:last-child .product-content { padding-right: 35px; }
.product .product-content { padding-right: 10px; }
.product .product-title { margin-bottom: 32px; font-size: 26px; line-height: 1.23; font-weight: normal; color: #00549c; }
.product .product-image { padding-bottom: 35px; }
.product .product-features .h5 { margin-bottom: 5px; }
.product .product-features ul { margin-bottom: 30px; list-style: none outside none; }
.product .product-features li { position: relative; padding: 0 0 5px 10px; }
.product .product-features li:before { content: '›'; position: absolute; top: -1px; left: 0; }
@media (max-width: 1023px) {
  .product .product-title { font-size: 20px; }
}
@media (max-width: 767px) {
  .product { width: 100%; }
  .product .product-features { display: none; }
  .product .product-title { font-size: 20px; }

  .product:first-child .product-content { padding-left: 15px; }
  .product:last-child .product-content { padding-right: 15px; }
  .product .product-content { padding: 0 15px; }
  .product .product-image { padding-bottom: 20px; }
  .product .product-title { margin-bottom: 20px; }
  .product .product-title br { display: none; }
}
/* ------------------------------------------------------------ *\
	Quote
\* ------------------------------------------------------------ */
.quote { font-size: 20px; color: #00549c; font-weight: bold; }
/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
.section { padding: 58px 0 0; }
.section .section-head { padding-bottom: 33px; }
.section .section-image img { width: 100%; }
/* Section Text */
.section-text { padding: 50px 0 0; }
.section-text .section-head { padding-bottom: 28px; }
/* Section Services */
.section-services .section-image { margin-bottom: 40px; }
/* Section Fundament */
/* Section Fundament */
.section-management .section-image { margin-bottom: 46px; }
/* Section Products */
.section-products .section-body:after { }
@media (max-width: 767px) {
  .section { padding: 15px 0; }
  .section .section-head { padding-bottom: 15px; }
  .section .section-image { margin-bottom: 25px; }

  .section-text .section-head { padding-bottom: 0; }

  .section-products { padding: 0; }
}
/* ------------------------------------------------------------ *\
	Section Subscribe
\* ------------------------------------------------------------ */
.section-subscribe { padding: 28px 0 0; }
.section-subscribe + .section { padding-top: 13px; }
.section-subscribe .section-head { padding-bottom: 15px; }
.section-subscribe .section-body:after { }
.section-subscribe .section-content { float: left; width: 600px; padding-top: 3px; }
.section-subscribe .section-aside { float: right; width: 410px; }
.section-subscribe .section-entry { font-size: 15px; line-height: 1.4; }
@media (max-width: 1200px) {
  .section-subscribe .section-aside { width: 330px; }
}
@media (max-width: 1023px) {
  .section-subscribe .section-content{ width: 50%; }
  .section-subscribe .section-aside { width: 50%; }
}
@media (max-width: 767px) {
  .section-subscribe { padding: 15px 0; }
  .section-subscribe .section-content,
  .section-subscribe .section-aside { float: none; width: auto; }

  .section-subscribe .section-content { padding-bottom: 15px; }
  .section-subscribe .section-entry { font-size: 16px; }
}


/* ------------------------------------------------------------ *\
	Section Support Intro
\* ------------------------------------------------------------ */

.section-support-intro {
  font-size: 14px;
  padding-top: 30px !important;
}


/* ------------------------------------------------------------ *\
	Bubble
\* ------------------------------------------------------------ */
.text-bubble { text-align: right; padding-right: 38px; margin-top: 7px; }
.text-bubble-inner { position: relative; display: inline-block; width: 370px; padding: 14px 22px 12px; border: 4px solid #0054a0; text-align: left; font-size: 14px; font-weight: bold; }

.text-bubble-inner:before,
.text-bubble-inner:after { position: absolute; bottom: -4px; left: 100%; border: 20px solid transparent; content: ''; border-left-color: #0054a0; border-bottom-color: #0054a0; }
.text-bubble-inner:after { margin: 0 0 4px -8px; border-left-color: #fff; border-bottom-color: #fff; }

@media (max-width: 767px) {
  .text-bubble-inner { width: auto; }
}

/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */
.section-headline {
  padding-top: 0;
}
.section-article .section-image { position: relative; padding-top: 300px; margin-bottom: 65px; background-position: center center; background-size: cover; background-repeat: no-repeat; }
.section-article .section-image .section-ribbon { position: absolute; top: 100%; left: 50%; margin: -25px 0 0 480px; }

.section-article .section-caption { max-width: 950px; margin: 0 auto; color: #fff; }
.section-article .section-caption h1 { color: #fff; font-weight: bold; }
.section-article .section-caption-inner { position: relative; width: 408px; max-width: 100%; padding: 35px 48px 40px 0; background: rgba(0, 84, 159, 0.68) }
.section-article .section-caption-inner:before { position: absolute; top: 0; bottom: 0; right: 100%; width: 100vw; background: rgba(0, 84, 159, 0.68); content: ''; }

.section-article .article { margin: 0 auto; }

.section-article .cols { margin: 0 -30px; }
.section-article .col { padding: 0 30px; }
.section-article .article h3,
.section-article .article .h3 { margin-bottom: 17px; font-size: 16px; }

@media (max-width: 1200px) {
  .section-article .section-image .section-ribbon { display: none; }
}

@media (max-width: 767px) {
  .section-article .section-image { padding-top: 100px; margin-bottom: 24px; }
  .section-article .section-caption-inner { padding: 20px 20px 20px 0; }
}

/* ------------------------------------------------------------ *\
	Support
\* ------------------------------------------------------------ */

.support { position: relative; padding-top: 45px; margin-bottom: 5px; overflow: hidden; }
.support h2 { padding-left: 100px; }

.support-inner { display: table; width: 100%; }

.support-content,
.support-image,
.support-contact { display: table-cell; padding: 27px 20px 22px; vertical-align: top; }

.support-image { width: 292px; border: solid #fff; border-width: 0 3px; min-height: 267px; background-position: center center; background-size: cover; background-repeat: no-repeat; }

.support-content { position: relative; padding-left: 100px; padding-right: 50px; background: #e6e6e6; }

.support-contact { position: relative; width: 264px; background: #00549f; text-align: center; color: #fff; font-size: 14px; line-height: 1.57; }
.support-contact a { color: #fff; }
.support-contact a:hover { color: #fff; }

.support-upload-icon { margin-bottom: 15px; }

.support-holder { position: relative; }

.support-holder:before { position: absolute; top: 0; bottom: 0; left: 100%; width: 100vw; background: #00549f; content: ''; }
.support-holder:after { position: absolute; top: 0; bottom: 0; right: 100%; width: 100vw; background: #e6e6e6; content: ''; }

.support-contact h3,
.support-contact .h3,
.support-contact p { text-align: left; }

.support-contact { padding-right: 0; }
.support-contact i { margin-bottom: 12px; }
.support-contact h3, .support-contact .h3 { margin-bottom: 12px; color: #fff; }
.support-contact p { margin: 0; }
.support-contact p span { display: inline-block; width: 70px; }
.support-contact p a { word-break: break-all; }

@media (max-width: 1200px) {
  .support h2 { padding-left: 0; }
  .support-content { padding-left: 0; }
}

@media (max-width: 1023px) {
  .support-content,
  .support-image,
  .support-contact,
  .support-inner { display: block; }

  .support-image { width: auto; min-height: 400px; margin: 0 -24px; border: 0 none; background-position: left 50% top -30px; }

  .support-contact,
  .support-content { width: auto; padding: 24px; margin: 0 -24px; }

  .support-contact h3, .support-contact .h3,
  .support-contact p { text-align: center; }

  .support-holder:before,
  .support-holder:after { display: none; }
}

@media (max-width: 767px) {
  .support { padding-top: 30px; margin-bottom: 0; }
  .support-image { width: auto; height: auto; padding-top: 44%; margin: 0 -24px; border: 0 none; min-height: 0; background-position: left 50% top -30px; }

  .support-inner { position: relative; }

  .support-contact i { display: none; }

  .support-contact { padding: 16px 24px; }
  .support-contact h3, .support-contact .h3,
  .support-contact p { text-align: left; }

  .support h2 { position: relative; padding-right: 28px; font-size: 16px; }
  .support h2:after { position: absolute; top: 0; bottom: 0; right: 0; width: 14px; height: 8px; margin: auto; background-image: url(/design/img/monacor/sprite.png); background-position: -252px -167px; content: ''; }
  .support.expanded h2:after { -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); transform: rotate(180deg); }

  .support-content { position: absolute; top: 0; left: 0; right: 0; z-index: 2; display: none; }

  .support.expanded .support-content { display: block; }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .support h2:after { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -246px -121px; background-size: 292px 210px; }
}


/* ------------------------------------------------------------ *\
	Employees
\* ------------------------------------------------------------ */

.employees { list-style: none outside none; margin: 0 -12px; font-size: 0; }

.employee { display: inline-block; vertical-align: top; width: 33.3%; padding: 0 12px; font-size: 14px; line-height: 1.43; }
.employee-inner { box-shadow: 0 2px 8px rgba(0,1,0,.15); }

.employee:nth-child(3) ~ .employee { margin-top: 24px; }

.employee-image { position: relative; height: 160px; background-color: #fff; background-repeat: no-repeat;  }


.employee-about { padding: 20px 25px; }
.employee-about p { margin: 0; }
.employee-about p a { word-break: break-all; }
.employee-about p span { display: inline-block; width: 63px; }
.employee-about h3, .employee-about .h3 { margin-bottom: 9px; }
.employee-about .employee-meta { margin-bottom: 13px; text-transform: uppercase; font-weight: normal; }

@media (max-width: 1023px) {
  .employee { width: 50%; }

  .employee:nth-child(2) ~ .employee { margin-top: 24px; }
}

@media (max-width: 767px) {
  .employee { display: block; width: auto; }

  .employee:nth-child(3) ~ .employee,
  .employee:nth-child(2) ~ .employee { margin-top: 0; }

  .employees-outer { margin: 0 -5px; }
  .employees { margin: 0; }
  .employee { padding: 5px; }

  .employees-outer .bx-wrapper .bx-pager.bx-default-pager { bottom: -35px; }
  .employees-outer .bx-wrapper .bx-pager.bx-default-pager a { background: #d8d8d8; }
  .employees-outer .bx-wrapper .bx-pager.bx-default-pager a.active { background: #888; }
}


/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Support Item
\* ------------------------------------------------------------ */
.support-items { margin: -10px; font-size: 0; list-style: none outside none; }
.support-item { display: inline-block; vertical-align: top; width: 33.33%; padding: 10px; font-size: 16px; color: #00549c; }
.support-item .support-item-inner { overflow: hidden; height: 121px; background: #e5eef5; border-radius: 18px; }
.support-item .support-item-image { float: left; padding: 24px 30px 0; }
.support-item .support-item-image img {  width: 74px; height: 74px; }
.support-item .support-item-content { overflow: hidden; padding: 18px 7px 0; }
.support-item .support-item-content .h5 { margin-bottom: 10px; }
@media (max-width: 1200px) {
  .support-item .support-item-image { padding: 24px 10px 0; }
}
@media (max-width: 1023px) {
  .support-item { width: 50%; }
}
@media (max-width: 767px) {
  .support-items { margin: 0 -15px; }
  .support-item { width: 100%; padding: 0 0 15px; }
  .support-item .support-item-image { padding: 24px 15px 0; }
}
/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
.table {}
/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */
.video { position: relative; }
.video .video-ph img { width: 100%; }
.video .video-actions { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); }
/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */
.widgets { list-style: none outside none; }


.element:first-of-type .section {
  margin-top: 0;
  padding-top: 0;
}
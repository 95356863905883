.jobform {

  .jobform-inputs li {
    padding-bottom: 10px;
  }

  .buttons {
    .fileinput {
      padding-bottom: 10px;
    }

    .btn {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }
    .btn input {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      opacity: 0;
      -ms-filter: 'alpha(opacity=0)';
      font-size: 200px !important;
      direction: ltr;
      cursor: pointer;
    }
  }

  .privacy label {
    font-size: 12px;
  }

  .file {
    line-height: 24px;
    .btn {
      padding: 1px 8px;
    }
    .btn.up {
      margin-right: 2px;
    }
    .fname {
      margin-left: 115px;
      white-space: nowrap;
    }
  }
}

.intl .jobform {
  background: #e6e6e6;
  padding: 25px;
  color: #000;
  position: relative;
  overflow: hidden;
}

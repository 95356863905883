

#searchbar {
  padding: 25px;
  position: absolute;
  z-index: 800;
  left: 0;
  right: 0;
  background: #fff;
  @include box-shadow(0 0 8px -2px rgba(0,0,0,.2));
  width: 1280px;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: $screen-xs-max) {
    padding-left: 14px;
    padding-right: 13px;
    padding-bottom: 15px;
    padding-top: 13px;
  }

  #searchbar_results {
    position: relative;
    background: #fff;

    @media (max-width: $screen-xs-max) {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      top: 119px;
      font-size: 12px;
    }
  }
  #searchbar_results_wrap {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1130;
    padding: 5px 15px 15px 15px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.2);
    margin-top: -1px;
  }


  .h4 {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
  }

  .item {
    @include clearfix();

    color: #000;
    margin-bottom: 5px;

    .thumbnail {
      float: left;
      border-color: #eee;
      background: #fff;
      padding: 5px;
      display: block;
      margin-right: 10px;
      margin-bottom: 0;
      height: 60px;
      width: 60px;

      img {
        max-height: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%)
      }
    }

  }
}

body.intl {
  #searchbar {
    padding: 20px;

    .btn, input[name="s"] {
      height: 39px;
    }
  }
  &.search-visible {
    #mainnav .navbar-header {
      box-shadow: none;
    }
    #app #header {
      box-shadow: none;
      border-bottom: 1px solid rgba(45, 55, 77, 1.00);
    }
    #searchbar {
      z-index: 1000;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    }
  }
}




/**
 * Force Search Display
 */
@media (min-width: $grid-float-breakpoint) {
  body.homepage, body.catalog, body.show-searchbar {

    &.jts.scrolling {
      padding-top: 190px;
    }
    &.monacor.scrolling {
      padding-top: 186px;
    }
    &.intl.scrolling {
      padding-top: 170px;
    }
    &.img.scrolling {
      padding-top: 184px;
    }

    &.jts.scrolling.product-tabs-sticky {
      padding-top: 190px + 42px;
    }
    &.monacor.scrolling.product-tabs-sticky {
      padding-top: 210px + 40px;
    }
    &.intl.scrolling.product-tabs-sticky {
      padding-top: 170px + 44px;
    }
    &.img.scrolling.product-tabs-sticky {
      padding-top: 184px + 44px;
    }


    li.search {
      display: none !important;
    }
    &:not(.design-v2) #searchbar {
      display: block !important;
      position: relative;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    }
    &.img #app #header {
      box-shadow: none;
      border-bottom: 1px solid rgba(45, 164, 125, 1.00);
    }
    &.intl #app #header {
      z-index: 1000 !important;
      box-shadow: none;
      border-bottom: 1px solid rgba(45, 55, 77, 1.00);
    }
    &.monacor #app #header {
      box-shadow: none;
      border-bottom: 1px solid rgb(237, 27, 36);
    }
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  body.intl #searchbar {
    position: fixed;
    top: 53px;
  }
  body.monacor #searchbar {
    position: fixed;
    top: 53px;
  }
  body.jts #searchbar {
    position: fixed;
    top: 49px;
  }
  body.img #searchbar {
    position: fixed;
    top: 54px;
  }
}

body.intl.scrolling #searchbar {
  position: fixed;
  top: 53px;
}
body.monacor.scrolling #searchbar {
  position: fixed;
  top: 49px;
}
body.jts.scrolling #searchbar {
  position: fixed;
  top: 49px;
}
body.img.scrolling #searchbar {
  position: fixed;
  top: 49px;
}


@media (max-width: $grid-float-breakpoint-max) {
  body.img {
    #app #header {
      box-shadow: none;
    }
    #searchbar {
      border-top: 1px solid rgba(45, 164, 125, 1.00);
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    }
  }
  body.img.scrolling #searchbar {
    position: fixed;
    top: 54px;
  }
}


.printorder {
  padding-top: 50px;

  .printorder-title {
    padding-left: 25px;
  }

  .printorder-inner {
    background: #efefef;
    padding: 25px;
    color: #000;
    position: relative;
    overflow: hidden;
  }

  .printorder-privacy {
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .printorder-details {
    position: relative;

    .printorder-image {
      padding-top: 40px;
      img { max-height: 400px; }
    }
  }

  .printorder-form {
    .printorder-fields {
      li { padding-bottom: 10px; }
    }

    .printorder-footer {
      padding-top: 15px;
      margin-bottom: 15px;
      position: relative;

      .printorder-catalogs {
        margin: 0;
        li { padding-bottom: 10px; }
      }
      .printorder-submit {
        position: absolute;
        bottom: 15px;
        right: 0;
      }
    }
  }


  .printorder-thanks {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
  .printorder-thanks-overlay {
    position: absolute;
    z-index: 20;
    top: 28%;
    color: #000;
    background: #fff;
    left: 30%;
    right: 30%;
    padding: 25px;

    .overlay-close {
      margin-top: -25px;
      margin-right: -25px;
      background: $brand-primary;
      color: #fff;
      height: 30px;
      width: 30px;
      font-size: 30px;
      line-height: 1;
      float: right;
      text-align: center;
      vertical-align: top;
      cursor: pointer;
    }
  }

  .printorder-outer {
    background: #cecece;
    padding: 25px;

    .btn:hover {
      color: #000;
    }
  }

  .printorder-help {
    padding-top: 20px;
    font-size: 13px;
  }

  .printorder-inner .printorder-thanks {
    display: none;
  }
  &.sent {
    .printorder-inner .printorder-thanks{
      display: block;
    }
  }

  @media (max-width: $screen-xs-max) {
    .printorder-submit {
      position: static !important;
    }
    .printorder-details{
      margin-bottom: 25px;
    }
    .printorder-inner .printorder-thanks-overlay {
      left: 15px;
      right: 15px;
      top: 35%;
    }
  }

}

.printorder-monacor .printorder-title {
  padding-left: 0 !important;
}
.printorder-monacor {
  padding-bottom: 0;
}

.printorder-monacorinternational .section-head {
  padding-top: 50px;
  padding-bottom: 10px;
}



.printorder-img {
  .section-head, .shell {
    padding: 0;
    text-align: left;
    color: #000;
  }
  .printorder-text {
    font-size: 14px;
  }
  .printorder-catalogs label {
    font-size: 15px;
  }
  .printorder-privacy {
    line-height: 1.1;
    font-size: 15px;
  }
  .printorder-privacy small {
    font-size: 12px;
  }
}

.printorder-jts {

  input[type=checkbox] {
    display:none;
  }
  input[type=checkbox] + label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right: 15px;
    color: #8ba2d1;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
  input[type=checkbox]:checked + label:before {
    content:"\2714"; /* Tick */
    color: #8ba2d1;
    text-align:center;
    line-height:21px;
  }
}

.printorder-img {

  input[type=checkbox] {
    display:none;
  }
  input[type=checkbox] + label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right: 15px;
    color: #34c89e;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
  input[type=checkbox]:checked + label:before {
    content:"\2714"; /* Tick */
    color: #34c89e;
    text-align:center;
    line-height:21px;
  }
}
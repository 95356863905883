

.element-monacorevents_eventform {
  margin-top: 50px;
}

.event-signup {
  padding: 25px 0;
  background: #f3f3f3;

  .form {
    max-width: 66%;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

}

.element-monacorevents_eventlist {
  margin-top: 50px;
  margin-bottom: 50px;
}

.event-list {
  // max-width: 66%;
  // @media (max-width: 1150px) {
  //   max-width: 80%;
  // }
  // @media (max-width: 910px) {
  //   max-width: 100%;
  // }
  .event {
    margin-bottom: 30px;
    padding: 15px;
    background: #f3f3f3;
  }
  .event-image {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: rgb(228, 228, 228);;
    min-height: 100px;

    img {
      max-height: 120px;
    }

    @media (max-width: 767px) {
      min-height: 250px;
      margin-bottom: 15px;

      img {
        display: block;
        margin: 0 auto;
        max-height: 250px;
      }
    }
  }

}



#marken {
  background: #F2F2F3;
  margin-top: 75px;
  margin-bottom: 25px;
  padding: 0 25px;
  height: 96px;

  .slogan {
    padding-top: 20px;
    font-size: 13px;
  }

  .logos {
    @include clearfix();
    .row {
      margin: 0 -4px;
    }
    .row > div {
      padding: 0 2px;
    }
    a {
      text-align: center;
      line-height: 1;
      position: relative;
      min-width: 100%;
      max-width: 206px;
      float: left;
      display: block;
      background: #EBEBEC;
      color: #575757;
      height: 96px;
      img {
        display: block;
        margin: 0 auto;
        transition-duration: 0.4s;
      }
      span {
        font-size: 9px;
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
      }
      &:hover img {
        transform: scale(1.1);
      }
    }
    .img img { height: 40px; }

    .monacor {
      padding-top: 13px;
    }
    .img {
      padding-top: 20px;
    }
    .jts {
      padding-top: 30px;
    }
  }


  @media (max-width: $screen-xs-max) {
    margin-top: 40px;
    height: auto;
    padding: 0;

    .slogan {
      font-size: 14px;
      text-align: center;
      padding: 15px 30px;
    }

    .logos a {
      height: 80px;
    }
    .logos a span {
      display: none;
    }
  }
}


.section-marken h1 {
  margin-top: 45px;
}

.marken-images .row,
.marken-sitemap .row {
  margin: 0;
  > div {
    padding: 0;
  }
}

.marken-images {
  img {
    min-width: 100%;
  }
}

.marken-caption {
  padding: 25px;

  @media (max-width: $screen-xs-max) {
    padding: 25px 15px;
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
    color: $brand-primary;
    margin-bottom: 0;

    @media (max-width: $screen-xs-max) {
      font-size: 20px;
    }
  }
}

.marken-sitemap {

  ul {
    list-style: none;

    li {
      padding-bottom: 15px;
      font-size: 14px;
    }

    a {
      font-weight: bold;
    }

    ul {
      list-style: none;
      padding-left: 15px;

      li {
        padding-top: 8px;
        padding-bottom: 0;
      }
      li:before {
        content: "\e258";
        font-family: 'Glyphicons Halflings';
        font-size: 9px;
        float: left;
        margin-top: 4px;
        margin-left: -17px;
        color: #666;
      }

      a {
        font-weight: normal;
      }
    }
  }

  .marke {
    padding: 0 25px;
    > ul {
      padding-left: 0;
    }

    a {
      color: #000;
    }
  }

}
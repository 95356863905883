#product .viewer {
  z-index: 600;
  position: relative;
}
#product .overview .social {
  z-index: 601;
}


.youtube-thumb {
  position: relative;

  .youtube-thumb-icon {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    font-size: 60px;
    text-shadow: 0px 1px 1px #fff;
  }

  img {
    transform: none !important
  }
}

.thumbnails .youtube-thumb {
  transform: translateY(16%);
}
.thumbnails .youtube-thumb-icon {
  font-size: 28px;
  text-shadow: none;
}
.modal-header .thumbnails .youtube-thumb {
  transform: translateY(0);
}
.modal-header .thumbnails .youtube-thumb-icon {
  font-size: 40px;
  text-shadow: none;
}

.video-icon {
  padding: 9px;
  padding-left: 10px;
  border-radius: 50%;
  width: 34px;
  height: 35px;
  font-size: 11px;
  margin-right: 4px;
  margin-left: 3px;
}

body.img .video-icon {
  border: 2px solid rgb(48, 200, 158);
  color: rgb(48, 200, 158);
}

body.intl .video-icon {
  border: 2px solid #105ea1;
  color: #105ea1;
}

body.monacor .video-icon {
  border: 2px solid #ed1b24;
  color: #ed1b24;
}

body.jts .video-icon {
  border: 2px solid #8aa3d4;
  color: #8aa3d4;
}




// Disclaimer Fine-print
.disclaimer .text {
  @media (max-width: $screen-xs-max) {
    font-size: 9px;
    line-height: 1.5;
  }
}

// Buttons
#buy_button, #fav_button {
  text-decoration: none;
}

// Händlerlinks
.extlinks {
  padding-top: 35px;
  padding-bottom: 10px;

  .extlinks-title {
    font-size:90%;
    margin-bottom:5px
  }
  .extlinks-link {
    text-decoration: none;
    display: inline-block;
    text-align: center;
    margin-left: 15px;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    width: 85px;
    vertical-align: text-top;
    min-height: 48px;

    img {
      max-width: 100%;
    }
  }
}



body.product-tabs-sticky {
  .tabs {
    position: fixed;
    z-index: 80;
    left: 0;
    right: 0;
    top: 132px;
    background: #fff;
    box-shadow: 0 3px 5px -2px rgba(0,0,0,.5);
    max-width: 1280px;
    margin: 0 auto;
  }
}

body.jts.product-tabs-sticky .tabs {
  top: 135px;
}


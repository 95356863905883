.career-statement {
  margin-top: 40px;

  .career-container {
    background: #E6E6E6;
    display: table;
    width: 100%;
    > div {
      display: table-cell;
    }
  }

  .career-image {
    height: 100%;
    border: solid #fff;
    border-width: 0 3px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  .career-name  {
    height: 100%;
    background: #00549C;
    color: #fff;
    vertical-align: bottom;
    padding: 10px;
    line-height: 1;
    small {
      color: #ccc;
    }
  }

  .career-text {
    height: 100%;
    background: #E6E6E6;
    vertical-align: top;
    padding: 25px 0;
  }
}


.career-statement {
  @media (max-width: $screen-xs-max) {
    .career-image {
      width: 50%;
      height: 150px;
      border-left: 0;
    }
    .career-name  {
      width: 50%;
      height: 150px;
    }
    .career-text {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
      padding: 15px 0;
    }
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .career-image {
      min-width: 235px;
    }
    .career-name  {
      min-width: 235px;
    }
  }
  @media (min-width: $screen-md-min) {
    .career-image {
      min-width: 275px;
    }
    .career-name  {
      min-width: 275px;
    }
  }
}

#mainnav .nav > li {
  > a[href*="webshop"],
  > a[href*="webshop"]:hover {
    background: rgb(0, 84, 159) !important;
    color: rgb(255, 255, 255) !important;
  }
}

// mini navigation (>break only)

#mininav {
  min-height: 30px;

  margin: 0;
  margin-top: -1px;
  margin-right: -1px;

  display: none;
  @media (min-width: $grid-float-breakpoint) {
    display: block;

    position: absolute;
    top: 0;
    right: 0;
    z-index:  10;

  }

  .item {
    font-size: 13px;
    background: #E1E1E1;
    color: #000;
    padding: 4px 20px;
    text-align: center;
    float: left;
    display: inline-block;
    a { color: #000; }
  }
  .item:first-of-type {
    border-bottom-left-radius: 6px;
  }
  .item.lang {
    padding-right: 45px;
  }
  .item.lang .dropdown-menu {
    margin-right: -1px;
    margin-top: -3px;
    a { color: #fff; }
    a:hover { color: rgb(0, 84, 156); }
  }


}

// main navigation


.favorites-empty .badge {
  display: none;
}


#mainnav {
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 25px;
  line-height: 20px;
  border: 0;

  .navbar-brand {
    padding: 0;
    padding-left: 25px;
    margin-top: 20px;

    > img {
      height: 35px;
    }
  }

  .navbar-toggle {
    background: transparent;
    margin: 0;
    font-size: 25px;
    padding: 15px;
    padding-bottom: 18px;
    border: 0;
    color: #000;
  }

  .menu-close {
    padding: 0 1px;
  }

  .navbar-nav>li>a {
    line-height: 24px;
  }

  .nav>li>a {
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 400;
    color: $black;
    border: 0;
    text-decoration: none;
  }

  .nav>li>a:hover,
  .nav>li>a:focus,
  .nav>li.active>a {
    color: $primary !important;
    background: transparent;
  }

  .nav>li.search>a {
    overflow: hidden;
    line-height: 18px;
    font-size: 17px;
  }


  .toggle-favorites .badge {
    position: absolute;
    z-index: 10;
    margin-left: -6px;
    margin-top: -7px;
    background: $primary;
    font-weight: normal;
    padding: 4px 6px;
  }

  .dropdown-menu {
    padding: 0;
    border: 0;
  }
  .dropdown-menu>li {
    border-bottom: 1px solid $black20;
  }
  .dropdown-menu>li:last-of-type {
    border-bottom: 0;
  }
  .dropdown-menu>li>a {
    text-decoration: none;
    padding: 10px 30px;
    color: #000;
  }
  .dropdown-menu>li>a:hover {
    background-color: #fff;
    color: $primary !important;
  }

  span.caret {
    margin-left: 0;
    margin-top: 3px;
    float: right;
  }
  .navbar-collapse {
    border-top: 0;
  }
  .navbar-nav {
    margin: 0 -12px;
  }

  .navbar-header {
    .toggle-search, .toggle-favorites {
      display: none;
    }
  }

  .nav>li>a:hover, .nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
    background: transparent !important;
    color: $primary !important;
  }


  .dropdown-toogle {
    display: none;
  }

  @media (max-width: $grid-float-breakpoint-max) {

    .dropdown-toogle {
      position: absolute;
      right: 0;
      top: 0;
      outline: 0;
      width: 48px;
      height: 45px;
      float: right;
      background: #fff;
      border: 0;
      padding: 0;
      border-left: 1px solid rgb(204, 204, 204);
      border-bottom: 1px solid rgb(204, 204, 204);
      display: flex;
      justify-content: center;
      align-items: center;
      .caret {
        float: none;
      }
    }
    .open .dropdown-toogle {
      color: #fff;
      background-color: rgb(0, 84, 156);
      border-left: 1px solid rgb(0, 84, 156);
      border-bottom: 1px solid rgb(0, 84, 156);
      .caret {
        transform: rotate(180deg);
        margin-top: -3px;
      }
    }
    .nav .open > a {
      border-bottom: 1px solid rgb(0, 84, 156);
    }

    .toggle-favorites .badge {
      margin-left: -8px;
      margin-top: -7px;
    }

    .navbar-brand {
      padding-left: 13px;
      margin-top: 17px;
      height: auto;
      img {
        height: 20px;
      }
    }

    .caret {
      display: block !important;
    }

    .search, .merkliste {
      display: none !important;
    }

    .navbar-header {
      @include box-shadow(0 5px 8px -1px rgba(0,0,0,.2));

      .toggle-search, .toggle-favorites {
        display: block;
        position: relative;
        float: right;
        padding: 15px;
        color: #000;
      }

      .toggle-favorites {
        margin-right: 4px;
        font-size: 20px;
      }

      .toggle-search {
        font-size: 20px;
        margin-right: 3px;
      }
    }

    .nav>li {
      border-bottom: 1px solid $black20;
    }
    .nav>li>a {
      padding: 10px 14px;
    }

    .nav>li.black20bg {
      border-bottom-color: #fff;
    }

    .nav>li>a {
      padding-left: 25px;
    }
    .dropdown-menu {
      > li > a {
        padding: 15px;
        padding-left: 35px;
        padding-right: 0;
      }
    }
    li.visible-xs {
      display: block !important;
    }

  }

  @media (min-width: $grid-float-breakpoint) {

    min-height: 80px;

    #menu {
      position: absolute;
      bottom: 0;
      right: 10px;
      z-index:  5;

      min-height: 60px;

      .nav {
        margin: 0;
      }

      .nav>li>a {
        padding-top: 80px;
      }
    }

    .navbar-nav {
      float: right !important;
      margin-bottom: 5px;
    }

    .dropdown-menu {
      background: rgba(0, 84, 156, 0.8);

      > li {
        border: none;
      }
      > li > a {
        color: #fff;
        padding: 20px 30px;

        background-repeat: no-repeat;
        background-position: 25px center;
        background-size: 60px;

        &[href*="marken/monacor"], &[href*="brands/monacor"] {
          background-image: url('/design/img/menu-monacor-white.png');
          padding-left: 110px;
        }
        &[href*="marken/img"], &[href*="brands/img"] {
          background-image: url('/design/img/img-stageline-white.png');
          padding-left: 110px;
        }
        &[href*="marken/jts"], &[href*="brands/jts"] {
          background-image: url('/design/img/jts-white.png');
          padding-left: 110px;
        }
        &[href*="marken/feimex"], &[href*="brands/feimex"] {
          background-image: url('/design/img/feimex-white.png');
          padding-left: 110px;
        }
      }
      > li > a:hover {
        &[href*="marken/monacor"], &[href*="brands/monacor"] {
          background-image: url('/design/img/menu-monacor-black.png');
        }
        &[href*="marken/img"], &[href*="brands/img"] {
          background-image: url('/design/img/img-stageline.png');
        }
        &[href*="marken/jts"], &[href*="brands/jts"] {
          background-image: url('/design/img/jts.png');
        }
        &[href*="marken/feimex"], &[href*="brands/feimex"] {
          background-image: url('/design/img/feimex.png');
        }
      }
    }

    li.dropdown:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

}

// Helper classes

.visible-menu-open {
  display: none !important;
}
.visible-menu-closed {
  display: block !important;
}
body.menu-visible {
  .visible-menu-open {
    display: block !important;
  }
  .visible-menu-closed {
    display: none !important;
  }
  #mainnav .navbar-toggle {
    background: $primary;
    color: #fff;
  }
}
.hidden-burger {
  display: none !important;
}
.visible-burger {
  display: block !important;
}
@media (min-width: $grid-float-breakpoint) {
  .hidden-burger {
    display: block !important;
  }
  .visible-burger {
    display: none !important;
  }
}

body.search-visible {
  @media (max-width: $grid-float-breakpoint-max) {
    #mainnav .navbar-header .toggle-search {
      color: $primary;
    }
  }
}



// fixed header
@media (max-width: $grid-float-breakpoint-max) {
  body {
    padding-top: 54px;
  }
  #app {
    position: relative;
    max-width: 100%;
    #header {
      background: transparent;
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1010;

      .navbar {
        background: #fff;
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  body {
    padding-top: 81px;

    #header {
      background: transparent;
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1010;
      .navbar {
        background: #fff;
      }
    }
    #mainnav {
      transition: min-height 400ms;
    }
    #app {
      position: relative;
      max-width: 100%;
    }
    @media (min-width: 1298px) {
      #app #header {
        width: 1280px;
      }
    }
  }

  body.scrolling {
    #mainnav {
      margin-top: 0;
      min-height: 54px;

      .navbar-brand {
        margin-top: 9px;
        height: 30px;
        >img {
          height: 30px;
        }
      }
    }
    #mininav {
      display: none;
    }
    #app {

    }
  }
}



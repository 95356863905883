


#gallery {

  @media (min-width: $screen-md-min) {
    .modal-dialog {
      width: 1178px;
    }
  }

  .thumbnail {
    margin-right:15px;
    margin-bottom: 0;

    @media (max-width: $screen-xs-max) {
      width: 40px;
      height: 40px
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .modal-header {
    background: #eee;
  }

  .modal-title {
    margin-bottom: 15px;
    color: #000;
  }

  .item {
    width: 100%;
    text-align: center;

    img {
      display: inline-block !important;
      max-height: calc(100vh - 300px);
      margin: 0 auto;
    }
  }

}

body {
  background: #B9B9B9;
  font-size: 14px;
  line-height: 1.45;
}

h2, h3, .h2, .h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 0;

  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}


h3, .h3 {
  font-size: 20px;
}

a:focus {
  outline: thin dotted;
  outline: 0;
  outline-offset: 0;
}

.wrapper {
  padding: 0 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
  }
}

.hpad {
  padding: 0 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
  }
}

.block-container {
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }
}

.block-fade {

}

.block-grey {
  background-color: #F5F5F5;
}

.headline {
  .head {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .line {
    margin: 0 -5px;
    > div {
      padding-left: 2.5px;
      padding-right: 2.5px;
    }
    div { height: 5px }
    .line-primary div { background: $primary; }
    .line-secondary div { background: $gray; }
  }

  @media (max-width: $screen-xs-max) {
    .head { font-size: 17px; }
    .line div { height: 3px; }
    .line-secondary { display: none; }
  }
}


.btn {
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
}
.btn-sm {
  font-size: 12px;
}
.btn-xs {
  font-size: 11px;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
  background: $primary;
  border-color: $primary;
  color: #fff;
  box-shadow: none;
}

.btn-primary:hover {
  background: transparent;
  border-color: $primary;
  color: $primary;
}

.btn-default,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus {
  background: #ccc;
  border-color: #ccc;
  color: #000;
  box-shadow: none;
}

.btn-default:hover {
  background: transparent;
  border-color: #ccc;
  color: #000;
}
.btn-primary:hover {
  background: 0 0;
  border: 1px solid #00549C;
  color: #00549C;
}

.btn.dropdown-toggle {
  background: #ededed none repeat scroll 0 0;
  border-radius: 2px;
  color: #505050;
}
.dropdown-menu {
  background-color: rgb(0, 84, 156);
  margin-top: 0;
  li {
    a {
      color: #fff;
      &:hover {
        color: rgb(0, 84, 156);
      }
    }
  }
}

a {
  color: $primary;
  text-decoration: underline;

  .img-idle {
    display: inline-block;
  }
  .img-over {
    display: none;
  }
}
a:hover {
  color: $primary;

  .img-idle {
    display: none;
  }
  .img-over {
    display: inline-block;
  }
}


.teal { color: $primary; }
.black { color: $black; }
.black80 { color: $black80; }
.black60 { color: $black60; }
.black40 { color: $black40; }
.black20 { color: $black20; }

.tealbg { background-color: $primary; }
.blackbg { background-color: $black; }
.black80bg { background-color: $black80; }
.black60bg { background-color: $black60; }
.black40bg { background-color: $black40; }
.black20bg { background-color: $black20; }

.caret {
  top: 1px;
  border: none;
  width: 16px;
  height: 16px;
  font-size: 12px;
  position: relative;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: "\e259";
  }
}

.btn {
  padding: 5px 15px;
}

.form-control {
  border-color: #E3E3E3;
  height: 32px;
  border-width: 2px;
  border-radius: 4px;
}

.button {
  background: #eee;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  text-align: center;
  // text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.1;
  top: 25px;
  right: 25px;
}
.button-inline {
  position: static;
  top: auto;
  right: auto;

}
.button span {
  display: table-cell;
  vertical-align: middle;
}
.button-black {
  background-color: #000;
  color: #fff;
}
.button-teal {
  background-color: $primary;
  color: #fff;
}
.button-orange {
  background-color: #FF4434;
  color: #fff;
}
.button {
  width: 100px;
  height: 100px;

  @media (max-width: $screen-xs-max) {
    font-size: 10px;
    top: 13px;
    right: 13px;
    width: 60px;
    height: 60px;
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 12px;
    width: 80px;
    height: 80px;
  }
}

a.download {
  color: #000;
  display: inline-block;
  padding: 15px 15px 15px 0;
  text-decoration: none;
  &:before {
    background: $primary none repeat scroll 0 0;
    color: #fff;
    content: "\e025";
    display: inline-block;
    font-family: "Glyphicons Halflings";
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    text-align: center;
    width: 30px;
  }
}

.fce-header {
  margin-top: 25px;
}




input[type=radio], input[type=checkbox] {
  display:none;
}

input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 7px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}
input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 7px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}

input[type=radio]:checked + label:before {
  content: "\2022"; /* Bullet */
  color: $brand-primary;
  font-size:1.8em;
  text-align:center;
  line-height:19px;
}
input[type=checkbox]:checked + label:before {
  content: "\2714"; /* Tick */
  color: $brand-primary;
  text-align:center;
  line-height: 27px;
}

input[type=checkbox] + label {
  padding-left: 0;
}

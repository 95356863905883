
.section-marken h2 {
  margin-top: 50px;
}


.category-header {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.category-header-image {
  height: 300px;
  background-repeat: no-repeat;
  background-position: left center;
}
.category-header-slim {
  height: 150px;
  .category-header-image {
    height: 150px;

    @media (max-width: $screen-xs-max) {
      background-position: center center;
      background-size: cover;
    }
  }
}
.category-header-caption {
  position: absolute;
  bottom: 25px;
  left: 25px;
  margin: 0;
  color: #fff;

  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}


.category-header-jts {
  .category-header-image {
    background-position: top center;
  }
}

#category {
  .headline {
    margin-top: 25px;
    margin-bottom: 50px;
  }
  .link {
    color: $primary;
    text-decoration: underline;
  }
}

.introsection {
  h3, .h3 {
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  padding-top: 25px;
}



.caption-blocks {

  margin: 0 25px;

  .item {
    margin-bottom: 50px;

    a {
      display: table;
      width: 100%;
      position: relative;
      text-decoration: none;
    }
  }

  .image {
    min-height: 190px;
    display: table-caption;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }


  .caption {
    display: table-cell;
    vertical-align: middle;
    background: #A0A0A0;
    padding: 15px 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 0
  }

  .item a:hover {
    .caption {
      background: $brand-primary;
      color: #fff;
    }
  }

  @media (max-width: $screen-xs-max) {
    margin: 0 13px;
    .item {
      margin-bottom: 25px;
    }
    .caption {
      padding: 15px;
    }
  }
}

/* Monacor */
.category-header-monacor {
  height: 280px;
  background-image: url('/design/img/monacor/category-header.png');
  .category-header-image {
    position: relative;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 580px;
    height: 280px;
  }
}
.category-blocks-monacor {
  .image {
    background-size: contain;
  }
}
.manuals-downloads {
  #searchform {
    margin-top: 50px;
    .search-field {
      overflow: hidden;
    }
    .view-options {
      @media (max-width: $screen-sm-max) {
        margin-top: 20px;
      }
      .form-group {
        @media (max-width: $screen-sm-max) {
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
  table.manual-list {
    margin-top: 50px;
    width: 100%;
    .item {
      img {
        max-height: 35px;
      }
    }
    > tbody {
      > tr {
        background-color: #e6e6e6;
        td {
          border: none;
          line-height: 40px;
          padding: 5px 10px;
          vertical-align: middle;
          @media (max-width: $screen-sm-max) {
            vertical-align: top;
            line-height: 24px;
          }
          a {
            color: #505050;
            text-decoration: underline;
          }
        }
      }
      > tr:nth-of-type(2n+1) {
        background-color: #f2f2f2;
      }
      > tr:first-child {
        background-color: #fff;
        th {
          font-weight: normal;
          border: none;
          line-height: 40px;
          padding: 5px 10px;
        }
      }

    }
  }
  .pagebrowser {
    margin: 40px 0;
    .browsebox {
      background: #f2f2f2 none repeat scroll 0 0;
      display: inline-block;
      padding: 4px 10px;
      a {
        color: #505050;
        padding: 5px 10px;
        &.current {
          color: #000;
          font-weight: bold;
        }
        &:hover {
          color: #000;
        }
      }
    }
    .nextpagebox > a, .prevpagebox > a {
      color: #505050;
      display: inline-block;
      padding: 5px;
      &:hover {
        color: #000;
      }
    }
  }

}

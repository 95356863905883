.contactform-subscribe {
  padding-top: 15px;
}

.contactform-privacy {
  line-height: 1.2;
  color: #aaa;
  margin-bottom: 15px;
}

.contactform-fields {
  li {
    margin-bottom: 15px;
  }

  @media (max-width: $screen-xs-max) {
    input[name="city"] {
      margin-top: 15px;
    }
  }
}

.contactform-buttons {
  text-align: right;

  @media (max-width: $screen-xs-max) {
    text-align: center;
    button[type=reset] {
      display: none;
    }
  }
}

.contactform {
  background: #eee;
  position: relative;

  .step1, .step2 {
    padding: 25px;
  }
}

.contactform-right {
  position: relative;
  .contactform-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.contactform h2 {
  font-weight: bold;
  margin-bottom: 25px;
}
.contactform .h4 {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 25px;
}

.contactform-close {
  cursor: pointer;
  background: #00549C;
  color: #fff;
  float: right;
  height: 37px;
  width: 37px;
  line-height: 30px;
  border: 0;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  margin-top: -25px;
  margin-right: -25px;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
.intro-home {
  background: #b9b9b9;
}

@media (max-width: $screen-xs-max) {
  .intro-home {
    display: none !important;
  }
}

.newsblocks {
  padding-bottom: 50px;
}


#homepage {

  #brands {
    position: relative;
    max-height: 975px;
    overflow: hidden;

    .tab-content {
      background: #000;
    }
  }

  .tabs {
    position: absolute;
    z-index: 100;
    bottom: 0px;
    height: 75px;
    margin: 0;
    width: 100%;
    max-width: 1280px;
    border-bottom: 0;

    @media (max-width: $screen-xs-max) {
      height: 150px;
    }

    li {
      padding: 0;

      a {
        display: block;
        background: $primary;
        color: #fff;
        height: 75px;
        font-size: 15px;
        line-height: 1.5em;
        padding-left: 45px;
        padding-top: 15px;
        padding-right: 0;
        margin-right: 1px;
      }

      a, a:hover, a:focus {
        background: $primary;
        color: #fff;
        border-color: transparent;
        border-bottom: 0;
        text-decoration: none;
        outline: none;
      }


      &.active {
        a, a:hover, a:focus {
          background: rgba(0, 84, 156, 0.5);
          color: #fff;
        }
      }


      @media (max-width: $screen-xs-max) {

        a {
          border-bottom: 0;
          height: 75px;
          font-size: 13px;
          line-height: 1.5em;
          padding: 15px;
        }

        &.break {
          display: block;
        }
      }
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        a {
          border-bottom: 0;
          height: 75px;
          font-size: 13px;
          line-height: 1.5em;
          padding: 15px;
        }
      }
    }


    li:last-of-type a {
      margin-right: 0;
    }

    @media (max-width: $screen-xs-max) {
      li:nth-child(1) a {
        margin-bottom: 1px;
      }
      li:nth-child(2) a {
        margin-bottom: 1px;
        margin-right: 0;
      }
    }


  }

}

@media (max-width: $screen-xs-max) {
  .slick-dots {
    display: none !important;
  }
}

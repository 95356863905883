.as-text {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: normal;
  text-decoration: none;
  line-height: $line-height-base;
  margin-top: 0;
  margin-bottom: 1em;
  text-transform: none;

  &.nomargin {
    margin: 0;
  }

}

/* ====== Specification Tables ======= */

tr.spec {

  &.spec-indent .spec-name {
    padding-left: 35px !important;
  }
  &.spec-bold .spec-name {
    font-weight: bold;
  }

}

/* ====== Banner Elements ======= */


.responsive-banner {

  a {
    text-decoration: none;
    cursor: pointer;
    border: 0;
    outline: 0;
  }

  img {
    min-width: 100%;
  }

  .banner-desktop, .banner-mobile, .banner-tablet {
    display: none;
  }

  @media (max-width: 400px) {
    .banner-mobile {
      display: block;
    }
  }

  @media (min-width: 401px) and (max-width: 1023px) {
    .banner-tablet {
      display: block;
    }
  }

  @media (min-width: 1024px) {
    .banner-desktop {
      display: block;
    }
  }
}



/* ====== Grid Elements ======= */

.gridelement {

  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }

  .gridelement {
    padding: 0;
  }

  .shell, .wrapper, .section { // reset paddings
    margin: 0;
    padding: 0;
  }

  .fce-textmedia .ce-bodytext,
  .text-block { // use full column space
    width: 100% !important;
    max-width: 100% !important;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
  }
}

/* ====== Content Elements ======= */

.ce-align-left { text-align: left; }
.ce-align-center { text-align: center; }
.ce-align-right { text-align: right; }

.ce-table td, .ce-table th { vertical-align: top; }

.ce-textpic, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div { overflow: hidden; }

.ce-left .ce-gallery, .ce-column { float: left; }
.ce-center .ce-outer { position: relative; float: right; right: 50%; }
.ce-center .ce-inner { position: relative; float: right; right: -50%; }
.ce-right .ce-gallery { float: right; }

.ce-gallery figure { display: table; margin: 0; }
.ce-gallery figcaption { display: table-caption; caption-side: bottom; padding-top: 8px; font-size: 80%; color: #777; }
.ce-gallery img { display: block; }
.ce-gallery iframe { border-width: 0; }
.ce-border img,
.ce-border iframe {
  border: 2px solid black;
  padding: 0px;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-bottom: 10px;
}
.ce-intext.ce-right .ce-gallery { margin-left: 10px; }
.ce-intext.ce-left .ce-gallery { margin-right: 10px; }
.ce-below .ce-gallery { margin-top: 10px; }

.ce-column { margin-right: 10px; }
.ce-column:last-child { margin-right: 0; }

.ce-row { margin-bottom: 10px; }
.ce-row:last-child { margin-bottom: 0; }

.ce-above .ce-bodytext { clear: both; }

.ce-intext.ce-left ol, .ce-intext.ce-left ul { padding-left: 40px; overflow: auto; }

.ce-uploads { margin: 0; padding: 0; }
.ce-uploads li { list-style: none outside none; margin: 1em 0; }
.ce-uploads img { float: left; padding-right: 1em; vertical-align: top; }
.ce-uploads span { display: block; }

.fce-textmedia  {
  padding-bottom: 25px;
}

.fce-div {
  margin-bottom: 10px;
  margin-top: 10px;
  hr {
    margin: 0;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .fce-textmedia .ce-bodytext {
    max-width: 76%;
  }
}
@media (min-width: $screen-md-min) {
  .fce-textmedia .ce-bodytext {
    max-width: 66%;
  }
}

.news .fce-textmedia .ce-bodytext {
  max-width: 100% !important;
}


@media (max-width: $screen-xs-max) {
  .fce-textmedia {

    .ce-column:last-child {
      margin-right: 10px;
    }

    .ce-column {
      margin-bottom: 10px;
    }

    .ce-row {
      display: inline;
      margin-bottom: 0
    }

    .ce-intext {
      .ce-gallery {
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 516px) {
  .fce-textmedia {
    .ce-inner, .ce-outer {
      position: static;
      float: none;
      right: initial;
    }

    .ce-row,
    .ce-column {
      display: block;
      float: none;
      width: 100%;
    }
    .ce-column img {
      min-width: 100%;
    }
  }
}


.ce-textpic .fce-header { padding: 0; }


/* ====== Images ======= */

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

/* ====== Debugging ======= */

.xdebug-var-dump {
  line-height: 1;
  font-size: 12px;
  background-color: #1D1D1D;
  border: 4px solid #000;
  color: #BDBAB1
}

.xdebug-var-dump p {
  margin: 0;
  line-height: 1;
}
.xdebug-var-dump i {
  color: #696969;
  font-style: normal;
}
.xdebug-var-dump b {
  color: #DCD466;
  text-transform: uppercase;
}
.xdebug-var-dump small {
  font-size: 100%;
  color: #999;
}
.xdebug-var-dump font[color='#cc0000'] {
  color: #f0ad4e;
}
.xdebug-var-dump font[color='#4e9a06'] {
  color: #F0664E;
}
.xdebug-var-dump font[color='#75507b'] {
  color: #A94F4F;
}


.faq {
  margin-top: 30px;
}

.faq .panel-body {
  padding-bottom: 7px;
}

.faq .panel-title a:hover {
  color: #fff;
}

.faq .panel-group .panel + .panel {
  margin-top: 10px;
}

.at-error, .at-warning {
  color: red;
  padding-left: 5px;
}
.form-control-feedback {
  display: none !important;
}

.zoomContainer {
  z-index: 9000;
}

.in .nav .language-dropdown {
  float: none !important;
}

.projectform,
.newsletter-small {
  background: #e6e6e6;
  .input {
    padding-bottom: 15px;
  }
  .introtext {
    padding-bottom: 15px;
  }
}

.img .newsletter-small {
  padding: 25px 0;
  background: #efefef;
}

.jts .newsletter-small {
  padding: 25px 0;
  background: #000;
  color: #fff;
}


.projectform {
  .file-queue {
    padding-top: 20px;

    .file {
      line-height: 32px;
    }
    .fbtn {
      padding-right: 8px;

      .btn {
        background: #00549c;
        border-color: #ffffff;
        color: #fff;
        box-shadow: none;
        padding: 5px 10px;
        border-radius: 0;
      }
    }
  }
}
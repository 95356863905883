/* ====== Product Image Sizing ======= */

#product .about #related .product .image{
  text-align: center;
  margin: 0 auto;
  height: 150px;
  width: 100%;
}
#product .about #related .product .image img {
  max-height: 100% !important;
  min-width: initial !important;
}

#product .overview .image .viewer .item img {
  margin: 0 auto;
  display: inline-block;
  max-width: 100% !important;
  max-height: 100% !important;
}

.product-blocks .product-blocks-items .griditem .image{
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
}
.product-blocks .product-blocks-items .griditem .image img {

  max-width: auto !important;
  max-width: initial !important;

  width: auto !important;
  width: initial !important;

  height: auto !important;
  height: initial !important;

  flex-shrink: 0;
  align-self: center;
  max-height: 100% !important;

  max-width: 100% !important;
}

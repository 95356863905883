
#downloads-error {
  @media (max-width: $screen-xs-max) {
    float: none !important;
    width: auto !important;
  }
}

#download-search {
  @media (max-width: $screen-xs-max) {
    .col-xs-offset-1 {
      margin-left: 13px;
    }
  }
}



.download-search {
  padding-bottom: 25px;

  form {
    margin-top: -15px;
    background: #fff;
    padding-top: 0px;
    padding-bottom: 25px;
  }

  .downloads-mobile {
    padding: 25px;
    .h4 {
      margin-bottom: 15px;
    }
    li {
      padding-bottom: 5px;
    }
    img {
      margin-right: 10px;
      width: 30px;
    }
  }

  #downloads-error {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .downloads {

    .header {
      text-transform: uppercase;
      font-weight: 700;
      padding-bottom: 0;
      padding-top: 0;
      background: none;

      .row > div {
        padding-bottom: 10px;
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }
    }

    .model {
      border-top: 1px solid #000;
      margin-bottom: 25px;

      img {
        height: 22px;
        padding-right: 5px;
      }

      a {
        color: #000;
        font-size: 11px;
      }

      .row > div {
        padding-bottom: 5px;
        padding-top: 5px;
        //border-bottom: 1px solid #666;
        white-space: nowrap;
        height: 35px;
        line-height: 22px
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }
    }

  }

}

.download-jts {
  margin-top: 15px !important;
}

.download-intl {
  form {
    margin-top: 0;
    padding-top: 25px;
  }
}

#wrap {
  max-width: 1280px;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}

#app {
  background: #fff;
  overflow: hidden;

  #header {
    position: relative;
    z-index: 1010;
    background: #ffffff;
    @include box-shadow(0 5px 8px -1px rgba(0,0,0,.2));
    min-width: 310px;
    margin: 0 auto;
    border-bottom: 1px solid rgb(185, 185, 185);
  }

}
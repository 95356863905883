

// kontakt bubble

.flyout {
  position: fixed;
  z-index: 1000;
  bottom: 0px;

  overflow: hidden;
  transition: right 300ms ease-in-out;
  height: 260px;
  width: 350px;
  right: -10px;

  &.closed {
    right: -306px;
  }

  .flyout-close {
    margin-left: 15px;
    margin-right: 10px;
  }

  .flyout-handle {
    cursor: pointer;
    position: relative;
    top: 0;
    left: 20px;
    width: 25px;
    height: 100px;
    overflow: hidden;
    float: left;

    @include box-shadow(-1px 1px 4px 1px rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    background: $primary;
    color: #fff;

    span {
      line-height: 1;
      font-size: 14px;
      font-weight: bold;
      display: block;
      transform: rotate(270deg);
      transform-origin: 42px 38px;
      width: 25px;
      height: 100px;
    }

    @media (max-width: $screen-xs-max) {
      height: 80px;
      span {
        font-size: 12px;
        height: 80px;
        transform-origin: 35px 30px;
      }
    }
  }

  .flyout-content {
    margin-left: 45px;
    padding: 15px;

    @include box-shadow(-1px 1px 4px 1px rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    border-top-left-radius: 0;
    background: $primary;
    color: #fff;
  }

  .flyout-buttons{
    .btn, .btn:hover, .btn:focus {
      background-color: #CCDDEB;
      text-decoration: none;
      color: $primary;
    }
    .btn {
      width: 90%;
      text-align: left;
    }
    .btn:hover {
      border: 1px solid #CCDDEB;
      background-color: $primary;
      color: #fff;
    }
  }

  .flyout-button-item {
    margin-bottom: 10px;
  }

  .flyout-intro {
    margin-bottom: 25px;
  }

  .flyout-outro {
    margin-top: 20px;

    a { color: #fff; }
  }

}

.page {

  padding: 25px;
  padding-top: 40px;

  @media (max-width: $screen-xs-max) {
    padding: 13px;
    padding-top: 25px;
  }

  .title {
    font-size: 25px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 15px;
    // text-transform: uppercase;
  }

  .text {

  }

}

.productsections {

  .section {
    margin-bottom: 20px;

    .title {
      font-size: 18px;
    }

    .image {
      text-align: center;
      margin-bottom: 10px;
      img { min-width: 100%; }
    }

    .link {
      font-weight: bold;
    }

    a {
      display: block;
      color: inherit;
      position: relative;
    }

    a:hover {
      text-decoration: none;

      color: $primary;

      .title {
        color: $primary;
      }

      .link:after {
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: " \e258";
        font-size: 75%;
        padding-left: 5px;
      }
    }

    @media (max-width: $screen-xs-max) {
      .title {
        font-size: 15px;
      }
      .teaser, .link {
        font-size: 13px;
      }
    }
  }

}


.latestnews {

  .section {
    margin-bottom: 20px;

    a {
      display: block;
      color: inherit;
      position: relative;
      height: 100%;

      background: #fff;
      @include box-shadow(0px 1px 5px 0px rgba(0, 0, 0, 0.2));
    }

    a:hover {
      color: inherit;
      text-decoration: none;
      .link {
        color: $primary;

        &:after {
          display: inline-block;
          font-family: 'Glyphicons Halflings';
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: " \e258";
          font-size: 75%;
          padding-left: 5px;
        }
      }
    }

    .image {
      text-align: center;
      img { min-width: 100%; }
    }

    .about {
      padding: 15px;

      .title {
        font-size: 18px;
      }

      .link {
        font-weight: bold;
      }

      time {
        font-size: 13px;
        display: inline-block;
        padding-bottom: 8px;
      }
    }

    @media (max-width: $screen-xs-max) {
      .title {
        font-size: 15px;
      }
      .teaser, .link {
        font-size: 13px;
      }
    }
  }

}


.service {
  min-height: 320px;
  background: $secondary;
  color: #fff;

  img.img-responsive {
    margin: 0 0 0 4%;
  }

  .slogan {
    color: #fff;
    margin-top: 10px;
    font-size: 20px;
  }

  .service-row {
    margin-top: 40px;
  }

  .service-content {
    padding: 25px 40px;
  }


  .service-block {
    border: 2px solid #fff;

    .h4 {
      color: #fff;
      font-weight: bold;
      border-bottom: 2px solid #fff;
      margin: 0;
      font-size: 15px;
      line-height: 20px;
      padding: 10px 15px;
      text-align: center;
    }
    img {
      float: left;
      margin-left: 15px;
      margin-top: 23px;
    }
    div {
      font-size: 14px;
      padding: 25px 15px;
      margin-left: 55px;
    }
  }

  @media (max-width: $screen-xs-max) {

    .slogan {
      margin-top: 20px;
      font-size: 17px;
    }
    .service-content {
      padding: 13px;
    }
    .service-block {
      border: 2px solid #fff;
      .h4 {
        font-size: 14px;
      }
      div {
        font-size: 13px;
      }
    }
    .service-block {
      margin-bottom: 25px;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .service-block-1, .service-block-2 {
      margin-bottom: 25px;
    }
  }
}


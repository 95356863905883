.brand-box {
  margin-top: 50px;

  .brand-logo {
    height: 40px;
    img { max-height: 100%; }
    margin-bottom: 15px;
  }

  .brand-container {
    padding: 15px;
  }

  .brand-more {
    padding-top: 15px;
    text-align: right;
  }

  .brand-results {
    article {
      height: 80px;
      background: #fff;
      color: #000;
      border-bottom: 1px solid #eee;
      width: 100%;

      padding: 5px 0;

      > a {
        display: flex;
        width: 100%;
        text-decoration: none;
      }

      .image {
        width: 120px;
        height: 64px;

        text-align: center;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          display: inline-block;
          max-height: 60px;
          max-width: 80px;
        }
      }

      .text {
        width: 90%;
        text-decoration: none;
      }
      .text:before {
        display: block;
        border: none;
        width: 16px;
        height: 16px;
        font-size: 16px;
        position: relative;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        font-family: 'Glyphicons Halflings';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e258";
        float: right;
        margin-top: 26px;
        margin-right: 8px;
        color: rgb(153, 153, 153);
      }


      .title {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .teaser {
        font-size: 14px;
        margin-bottom: 0;
        color: #000;
      }
    }
  }


  &.brand-img {
    .brand-container {
      background: rgb(102, 102, 102);
      color: #aaa;
      a {
        color: rgb(86, 204, 158)
      }
      article {
        border-bottom-color: rgb(183, 183, 183);
      }
    }
  }

  &.brand-jts {
    .brand-container {
      border: 1px solid #000;
      background: rgb(51, 51, 51) url(/design/img/jts/bg.gif);
      color: rgb(255, 255, 255);
      .title, .brand-more a {
        color: rgb(139, 161, 213);
      }
    }
  }

  &.brand-monacor {
    .brand-container {
      background: rgb(237, 28, 36);
      color: #aaa;
      a {
        color: #aaa;
      }
      .title {
        color: #000;
      }
      .brand-more a {
        color: #fff;
      }
    }
  }

  &.brand-monacorinternational {
    .brand-logo img {
      margin-top: 13px;
      height: 25px;
    }

    .brand-container {
      background: #025499;
      color: #fff;
      a {
        color: #025499;
      }
      .brand-more a {
        color: #fff;
      }
    }
  }

}

.jts .brand-box {
  margin-bottom: 40px;
}

.jts .brand-box.brand-monacorinternational {

  .brand-logo img {
    margin: 4px 0;
  }

}
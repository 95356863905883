// haendler.html

.imagegrid {
  padding-top: 25px;
  padding-bottom: 25px;
}

.imagegrid-header {
  margin: 0;
}

.imagegrid-grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -1px;

  .imagegrid-item {
    display: block;
    position: relative;
    margin: 10px;
    .embed-responsive {
      padding-bottom: 100%;
    }
  }

  &.grid-4 .imagegrid-item {
    width: calc(25% - 20px);
    @media (max-width: 1199px) {
      width: calc(33.333% - 20px);
    }
    @media (max-width: 900px) {
      width: calc(50% - 20px);
    }
  }

  &.grid-5 .imagegrid-item {
    width: calc(20% - 20px);
    @media (max-width: 1199px) {
      width: calc(25% - 20px);
    }
    @media (max-width: 900px) {
      width: calc(33.333% - 20px);
    }
    @media (max-width: 767px) {
      width: calc(50% - 20px);
    }
  }

  .imagegrid-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
}
/**
 * Shared rules for /news/detail
 */
.news-detail {

  .section-body .element {

    padding-top: 25px;
    &:first-of-type {
      padding-top: 0;
    }

    .ce-media {

      position: relative;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (9 / 16) * 100%;
      }

      iframe,
      embed,
      object,
      video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100% !important;
        width: 100% !important;
        border: 0;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        margin: 0 auto;
      }

    }

  }

}
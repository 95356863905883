/* Slider */
.slick-slider
{
  position: relative;

  display: block;

  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}


.bx-viewport {
  .slides, .slide, .intro-image {
    height: 100%;
    overflow: hidden;
  }
}

.slider {
  position: relative;
  .slides {
    list-style: none outside none;
  }
}

@media (min-width: 768px) {
  .slider-home {
    overflow: hidden;
    height: 620px;
    background: #b9b9b9;

    .slides {
      height: 620px;
      background: #b9b9b9;
      opacity: 0;
      transition: opacity 300ms;

      &.slick-initialized {
        opacity: 1;
      }
    }
    .slide {
      position: relative;
      height: 620px;
    }
  }
}

.slider-home {

  .slider-inner {
    position: relative;
  }

  .slide a {
    text-decoration: none
  }

  .slide-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
  }

  .slide-title {
    position: absolute;
    bottom: 135px;
    left: 0;
    width: 55%;
    padding-left: 36px;
    font-size: 60px;
    line-height: 1.17;
    color: #fff;
    font-weight: bold;
  }

  .slide-title {
    line-height: 1.2;
    background: rgba(0, 0, 0, 0.56);
    padding: 25px 50px 25px 28px;
    margin-left: -10px;
  }

  .slide-content {
    position: absolute;
    top: 76px;
    right: 0;
    z-index: 5;
    width: 345px;
    padding: 41px 34px 34px;
    border-radius: 20px 0 0 20px;
    background: rgba(0, 0, 0, .8);
    color: #fff;
  }

  .slide-entry {
    line-height: 1.75;
  }

  .slide-overlay {
    display: none;
    position: absolute;
    z-index: 100;
    right: 0;
    top: 80px;
    width: 300px;
    color: #fff;
    background: rgba(0,0,0,0.5);
    padding: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    .slide-overlay-image {
      padding-top: 15px;
      text-align: left;

      img {
        max-width: 150px;
        display: inline;
      }
    }
  }


  .slider-paging {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 1.25;
    color: #fff;

    ul {
      display: table;
      width: 100%;
      table-layout: fixed;
      list-style: none outside none;
    }

    li {
      display: table-cell;
      padding: 0 1px;
    }

    a {
      font-weight: bold;
      color: #fff;
      display: block;
      text-decoration: none;
      text-align: center;
      padding: 16px 25px 16px 25px;
      background: rgba(0, 86, 154, 0.8);
    }

    a:hover {
      color: #fff;
      text-decoration: none;
    }

    .current a {
      background: rgba(0, 86, 154, 0.2);
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 75px;
    left: 0;
    width: 100%;
    text-align: center;
    list-style: none outside none;
    font-size: 0;

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 3px;
    }

    button {
      width: 20px;
      height: 16px;
      border: 1px solid #fff;
      background: transparent;
      transform: skew(-14deg);
      outline: 0;
    }

    button:hover,
    .slick-active button {
      border: 1px solid #00549c;
      background: #00549c;
    }
  }
}

.slider-home .slick-active .slide-overlay {
  display: block;
}

@media (max-width: 1200px) {
  .slider-home .slider-paging a {
    height: 67px;
  }
}

@media (max-width: 1023px) {
  .slider-home .slide-title {
    width: 68%;
  }
  .slider-home .slide-overlay {
    width: 250px
  }

}

@media (max-width: 767px) {

  .slider-home .slide-image {
    position: static;
    padding-top: 40%;
  }

  .slider-home .slide-title {
    margin: 0;
    width: 100%;
    position: static;
    font-size: 22px;
    padding: 15px;
    background: #000;
    color: #fff;
  }
  .slider-home .slide-overlay {
    position: static;
    width: auto;
    padding: 15px;
    border-radius: 0;
    background: #000000;
    color: #A1A1A1;
  }

  .slider-home .slider-paging a {
    padding: 15px 10px;
  }

  .slider-home .slider-paging a {
    background: #00549C
  }

  .slider-home .slider-paging {
    position: static;
    margin-left: -1px;
    width: 101%;
    margin-bottom: 15px;
  }

  .slider-home .slider-paging ul {
    display: block;
  }

  .slider-home .slider-paging li {
    display: block;
  }

  .slider-home .slide-content {
    display: none;
  }

  .slider-home .slide {
    height: auto;
  }

  .slider-home .slick-dots {
    display: none
  }

  .slider-home .slider-paging a {
    height: auto;
  }

  .slider-home .slider-paging .current a {
    color: #000;
  }
}
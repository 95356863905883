section.section.section-management,
section.section.section-fundament {
  padding-top: 50px !important;
}

#element-2270, #element-2274 {
  background: #e6e6e6;
  padding-top: 25px;

  .ce-gallery {
    margin-bottom: 0;
  }
  .ce-row, .ce-textpic, .ce-gallery {
    overflow: visible;
  }
  img {
    transition: transform 0.2s ease;
    transform: scale(1);
  }
  a:hover img {
    transform: scale(1.1);
  }

  @media (max-width: 420px) {
    .ce-row {
      width: 100%;
    }
    .ce-column {
      width: 47%;
      float: left;
      min-height: 250px
    }
  }
}

.list-services {
  padding: 50px;
}

.employee-image {
  background-color: rgb(230, 230, 230);
}

.support {
  padding-top: 0 !important;
}


.support-contact-form {
  background: #e6e6e6;
  padding: 15px 0;
  border-top: 3px solid #fff;
}

.support-icon {
  margin-bottom: 10px;
}

.icon, .icon-blue, .icon-white {
  background-color: transparent;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: inline-block;
  width: 77px;
  height: 77px;
}

.icon-blue {
  background-image: url('/design/img/intl/service-icons-blue.png');
}
.icon-white {
  background-image: url('/design/img/intl/service-icons-white.png');
}

.icon-chat {
  background-position: 0 0;
}
.icon-cart {
  background-position: -77px 0;
}
.icon-checklist {
  background-position: -153px 0;
}
.icon-book {
  background-position: -230px 0;
}
.icon-parcel {
  background-position: -307px 0;
}
.icon-gear {
  background-position: -385px 0;
}
.icon-headset {
  background-position: -462px 0;
}
.icon-speaker {
  background-position: -539px 0;
}
.icon-transporter{
  background-position: -615px 0;
}
.icon-wrench {
  background-position: -692px 0;
}
.icon-car {
  background-position: -770px 0;
}
.icon-award {
  background-position: -850px 0;
}


.manuals-downloads {
  background: #F4F4F4;
  padding: 25px 0;
}
.manuals-downloads table.manual-list>tbody>tr:first-child {
  background-color: #00549C !important;
  color: #fff !important;
}


#support {



  header {
    background: #fff;

    padding: 50px 25px;
    @media (max-width: $screen-xs-max) {
      padding: 25px 13px;
    }
  }

  .supporter {
    background: $primary;
    padding: 25px;


    .contact-card {
      border: 2px solid #fff;
      @include clearfix();
      display: table;
      margin-bottom: 25px;
      &:nth-child(4) {
        margin-bottom: 0;
      }

      color: #fff;
      a { color: #fff; }

      .name {
        display: block;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 15px;
      }
    }

    .contact-cell {
      padding: 25px;
      display: table-cell;
      vertical-align: top;
    }

    .contact-image {
      width: 15%;
      border-right: 2px solid #fff;
      text-align: center;
      vertical-align: center;

      img {
        margin-bottom: 15px;
      }
    }

    .contact-info {
      width: 30%;
      border-right: 2px solid #fff;
    }

    .contact-about {
      width: 55%;
      font-size: 13px;
    }

    @media (max-width: $screen-sm-max) { // below tablet
      .contact-card, .contact-cell {
        display: block;
      }
      .contact-info {
        border-right: none;
      }
      .contact-image, .contact-info {
        height: 150px;
        float: left;
      }
      .contact-image {
        width: 30%;
      }
      .contact-info {
        width: 70%;
      }

      .contact-about {
        @include clearfix();
        width: 100%;
        display: block;
        margin-top: 150px;
        border-top: 2px solid #fff;
      }
    }
    @media (max-width: $screen-xs-max) {
      .contact-image, .contact-info, .contact-about {
        height: auto;
        border-right: none;
        width: 100%;
        display: block;
        float: none;
        margin-top: 0;
      }
      .contact-info {
        border-top: 2px solid #fff;
      }
      .contact-image {
        border-right: none;
        br:last-of-type { display: none; }
      }
    }
  }

  .search {
    padding-bottom: 35px;

    @media (max-width: $screen-xs-max) {
      padding-bottom: 0px;
    }
  }

  .downloads-mobile {
    padding: 25px;
    .h4 {
      margin-bottom: 15px;
    }
    li {
      padding-bottom: 5px;
    }
    img {
      margin-right: 10px;
    }
  }

  .downloads {

    .header {
      .row > div {
        padding-bottom: 10px;
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }
    }

    .model {
      margin-bottom: 25px;

      img {
        height: 22px;
        padding-right: 5px;
      }

      a {
        color: #000;
        font-size: 13px;
      }

      .row > div {
        padding-bottom: 10px;
        padding-top: 10px;
        background: #F2F2F2;
        white-space: nowrap;
        height: 45px;
        line-height: 22px
      }

      .row:nth-child(odd) > div {
        background: #E6E6E6;
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }

      .model-name {
        background: #000 !important;
        color: #fff;
      }

    }

  }

}


.printorder .printorder-inner {
  background: #e6e6e6;
}
.printorder .printorder-title {
  padding-left: 0;
  margin-bottom: 0;
}
.printorder .section .section-head {
  padding-bottom: 0;
}
.caption-image {
  position: relative;
  display: block;

  .image-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .image {
    height: 400px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 400ms ease;

    @media (max-width: $screen-xs-max) {
      height: 165px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      height: 300px;
    }
  }

  &:hover .image {
    transform: scale(1.05);
  }

  a {
    position: relative;
    display: block;
  }

  .title {
    position: absolute;
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    top: 15px;
    left: 25px;
    @media (max-width: $screen-xs-max) {
      font-size: 25px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 30px;
    }
  }

  .caption {
    font-size: 20px;
    position: absolute;
    background: rgba(0,0,0,0.6);
    padding: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    @media (max-width: $screen-xs-max) {
      font-size: 13px;
      padding: 13px;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }
}

.intl .caption-image .caption {
  background: rgba(0, 84, 156, 1);
}

.monacor .caption-image .caption {
  background: rgb(237, 28, 36);
}

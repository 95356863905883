#cookiebar {
  position: fixed;
  top: 0;
  z-index: 1500;
  width: 100%;
  left: 0;
  right: 0;

  .cookie-wrap {
    min-height: 65px;
    color: #fff;
    background: #7e7e7e;
    box-shadow: 0 3px 5px rgba(0,0,0,0.3);
    padding: 5px 25px;
    max-width: 1280px;
    margin: 0 auto;
  }
  .cookie-text {
    padding-top: 5px;
  }
  .cookie-button {
    line-height: 50px;
  }
}


.filter-container {
  .toggle-panelbody {
    display: block;
    text-decoration: none;
  }
}

.filter-labels {
  margin-bottom: 15px;

  .label {
    display: inline-block;
    font-weight: normal;
    font-size: 80%;
  }

  .label a {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    color: #fff;
  }
}

.intl .filter-labels .label a {
  color: rgb(181, 203, 223);
}
.img .filter-labels .label a {
  color: rgb(55, 140, 117);
}
.jts .filter-labels .label a {
  color: rgb(95, 120, 167);
}

.monacor #productlist.row {
  padding-top: 25px;

  @media (min-width: $screen-sm-min) {
    .filter-container {
      margin-left: -2px;
    }
  }
}
.monacor #productlist.row .block-container {
  padding-left: 0;
  padding-top: 0;

  @media (max-width: $screen-xs-max) {
    padding-right: 2px;
  }
}


.filter-loading {
  margin-bottom: 25px;
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.filter-groups {
  padding: 5px !important;
}

.range-selector {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

.filter-container {

  .panel-group {
    margin-bottom: 0;
  }
  .panel-heading {
    word-wrap: break-word;
  }

  .panel-body {
    padding: 0;
    overflow: hidden;
    ul {
      margin: 10px 15px;
    }
  }

  .disabled:not(.selected) {
    position: relative;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    input, select {
      opacity: 0.3;
    }
    label {
      color: $text-muted;
    }
  }


  .group-closed {
    > .panel-body {

    }
    > .panel-heading {
      .glyphicon-minus {
        display: none;
      }
      .glyphicon-plus {
        display: inline-block;
      }
    }
  }

  .group-open {
    > .panel-body {

    }
    > .panel-heading {
      .glyphicon-minus {
        display: inline-block;
      }
      .glyphicon-plus {
        display: none;
      }
    }
  }
}
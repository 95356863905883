

.newsletter {
  position: relative;
  .about {
    padding-bottom: 15px;
  }
}

.form-subscribe {
  .input {
    margin-bottom: 10px;
  }
}

.news-date {
  display: block;
  padding-bottom: 25px;
}

#news {

  .topnews {
    margin-top: 25px;
    margin-bottom: 50px;


    @media (min-width: $screen-md-min) {
      > .row {
        margin-left: -25px;
        margin-right: -53px;
      }
      .col-md-6 {
        padding-left: 0;
        padding-right: 0;
      }

    }


    .text {
      background: $primary;
      color: #fff;
      padding: 20px 60px 20px 75px;


      @media (max-width: $screen-sm-max) {
        padding: 25px;
      }

      h3, .h3 {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 25px;
        color: #fff;

        @media (max-width: $screen-sm-max) {
          margin-top: 13px;
        }
      }

      .more {
        font-weight: bold;
        color: #fff;
      }

      .content {

        padding: 15px;
        padding-left: 0;
        @media (max-width: $screen-sm-max) {
          padding: 13px;
        }
      }
      .footer {
        position: absolute;
        bottom: 30px;
        a {
          color: #fff;
        }
      }
    }

  }


  .articles {

    @media (min-width: $screen-md-min) {
      > .row {
        margin-left: -37px;
        margin-right: -38px;
      }
    }

    .item {
      position: relative;
      background: #eee;
      margin-bottom: 35px;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 20px;
      }

      .row {
        height: 100%;
      }

      .image {
        overflow: hidden;
        height: 100%;

        img {
          display: inline-block;
          min-width: 100%;
          min-height: 100%;
        }

        @media (max-width: $screen-sm-max) {
          height: 150px;
          img {
            display: inline-block;
            max-width: 100%;
            min-width: 100%;
            min-height: auto;
          }
        }
      }

      .text {
        postion: relative;
        height: 100%;

        h3, .h3 {
          font-weight: 700;
          margin-top: 0;
          margin-bottom: 15px;
          color: $primary;

          @media (max-width: $screen-sm-max) {
            margin-top: 13px;
          }
        }

        .more {
          font-weight: 700;
        }

        .content {
          padding: 15px;
          padding-left: 0;
          @media (max-width: $screen-sm-max) {
            padding: 13px;
          }
        }
        .footer {
          padding-bottom: 15px;
          @media (max-width: $screen-sm-max) {
            padding: 13px;
          }
        }
      }
    } // .item
  } // .articles

  .modal-close {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 14px;
  }
  .news-banner {
    position: relative;
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
  }

  .news-article {
    padding: 25px;
    header {
      padding-bottom: 15px;
    }
  }

  .news-article-text {
    img {
      width: 100% !important;
      height: auto !important;
    }

    column-count: 2;
    column-gap: 25px;

    @media (max-width: $screen-xs-max) {
      column-count: 1;
    }
  }
}

#content {
  .news-single {
    .article {

      .news-img-wrap {
        width: auto;
        max-width: 100%;
        margin: 0;
        float: none;
        background: transparent;

        iframe {
          width: 100% !important;
        }

      }
      .news-img-wrap .viewer a {
        float: none;
        padding: 0;
        border: none;
      }

      .image {
        padding-top: 25px;

        .viewer {
          text-align: center;

          img {
            margin: 0 auto;
            display: inline-block;

            @media (max-width: $screen-xs-max) {
              width: 100%;
            }
          }

          .item {
            padding: 0px;
            a {
              border: none;
              padding: 0;
            }
          }

          .carousel-indicators {
            bottom: -60px;
            li {
              border-color: #DEDEDE;
              background: #DEDEDE;
            }
            .active {
              background: #8D8D8D;
            }

            @media (max-width: $screen-sm-max) {
              bottom: 8px;
            }

            @media (min-width: $screen-sm-min) {
              display: none;
            }
          }

        }

        .images {
          padding-top: 15px;
          @include clearfix();
        }
        .badges {
          position: relative;
          padding-top: 10px;
        }

      }

      .thumbnails {
        position: relative;
        .thumbnail {
          float: left;
          cursor: pointer;
          border-color: #333;
          background: #000;
          padding: 5px;
          display: block;
          height: 80px;
          width: 33.333%;

          img {
            max-height: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%)
          }

          .glyphicon-plus {
            margin-top: 21px;
            margin-left: 22px;
          }
        }
        .active {
          border-color: $brand-primary;
        }
      }

    }
  }
}

#products {
  @media (max-width: $screen-xs-max) {
    .introsection {
      margin-bottom: 40px;
    }
  }

}

.marken-sitemap {
  h2 a {
    color: $brand-primary !important;
  }
}


.breadcrumb-container {
  background: #D8D8D8;
  padding-top: 12px;
  padding-bottom: 10px;

  .breadcrumb > .active {
    color: $primary;
  }
}


.filter {
  padding: 25px;
  padding-top: 35px;
  background: rgba(0,0,0,0.8);
  color: #fff;

  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
    padding-bottom: 50px;
  }

  h3, .h3 {
    color: #fff;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
  }

  .range-filter {
    margin-bottom: 15px;

    .slider {
      width: 100% !important;
    }
    .slider-handle {
      background-image: none;
      background-color: $primary;
      opacity: 1
    }
    .slider-track {
      background-image: none;
      background-color: #eee;
    }
    .slider-selection {
      background-image: none;
      background-color: #ccc;
    }
    .range-value {
      width: 30%;
      display: inline-block;
    }
    .range-values {
      padding-top: 20px;
      @include clearfix();
      text-align: center;
      line-height: 30px;
    }
    .range-value-from {
      float: left;
    }
    .range-value-to {
      float: right;
    }
  }
}


.checkbox-filter {
  li {
    padding-bottom: 10px;
  }

  label {
    font-weight: normal;
    cursor: pointer;
  }
}


input[type=radio], input[type=checkbox] {
  display:none;
}

input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 10px;
  color: $primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}
input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 15px;
  color: $primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}

input[type=radio]:checked + label:before {
  content: "\2022"; /* Bullet */
  color: $primary;
  font-size:1.8em;
  text-align:center;
  line-height:19px;
}
input[type=checkbox]:checked + label:before {
  content:"\2714"; /* Tick */
  color: $primary;
  text-align:center;
  line-height:21px;
}



.product-blocks-header {
  padding-top: 15px;
  padding-bottom: 15px;
  background: $secondary;

  .product-sort {
    text-align: right;
    margin-right: -14px;

    .btn {
      background: none!important;
      text-decoration: none!important;
    }

    .btn-link {
      color: $brand-primary;
    }

    .dropdown-toggle {
      color: #000;
    }

    .dropdown-menu {
      right: 1px;
      left: auto;
    }
  }

  .product-label {
    font-weight: bold;
    margin-left: -1px;
    padding: 8px 0;
    color: #000;
  }
}


.product-blocks-items {
  background: $secondary;
  padding-bottom: 20px;

  @media (max-width: $screen-xs-max) {
    padding: 13px;
    padding-top: 0;
  }

  .listitem {
    .item {
      display: block;
      position: relative;
      background: #fff;
      margin-bottom: 5px;
      padding: 15px;
      text-decoration: none;
      color: #000;

      &:hover {
        color: #000;
      }
    }
    .title {
      font-weight: bold;
      color: $brand-primary;
      margin-top: 0;
      margin-bottom: 5px;

      @media (max-width: $screen-xs-max) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .meta {
      font-size: 13px;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
      }
    }

    .teaser {
      font-size: 13px;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
      }
    }
    .price {
      text-align: right;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
        padding-top: 10px;
      }
    }

    .image {
      height: 56px;
      text-align: center;

      @media (max-width: $screen-xs-max) {
        height: 140px;
      }

      img {
        display: inline-block;
        max-height: 100%;
      }
    }
  }


  .griditem {
    .item {
      margin-bottom: 35px;
      display: block;
      background: #fff;
      text-decoration: none;
      border-radius: 10px;
      min-height: 400px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 20px;
      }

      @media (min-width: $screen-sm-min) {
        transition: transform 150ms ease-out;
        z-index: 1;
      }

      &:hover {
        @media (min-width: $screen-sm-min) {
          transform: scale(1.1);
          z-index: 100;
        }
      }

    }

    .image {
      padding: 35px;
      padding-bottom: 0;

      @media (max-width: $screen-xs-max) {
        padding: 13px;
        padding-bottom: 0;
      }
    }

    .image img {
      max-width: 100%;
      max-height: 100%;
      height: auto !important;
      width: auto !important;

      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .link {
      opacity: 0;

      background: $primary;
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 15px;
      text-align: center;
      // text-transform: uppercase;
      border: 1px solid $primary;

      @include border-bottom-radius(10px);

      transition: opacity 250ms ease-out;
    }

    .details {
      padding: 25px;
      color: #000;
      text-decoration: none;
      min-height: 200px;
      position: relative;

      .title {
        font-weight: 700;
        margin: 0;
        padding-top: 10px;
        font-size: 17px;
        padding-bottom: 3px;
      }

      .meta, .title {
        background: #F1F1F1;
        margin-left: -25px;
        margin-right: -25px;
        padding-left: 25px;
        padding-right: 25px;
      }

      .meta {
        padding-bottom: 10px;
        color: #333;
      }

      .meta, .teaser {
        font-size: 13px;
      }

      .teaser {
        padding-bottom: 20px;
      }

      .previous {
        text-decoration: line-through;
        color: #ccc;
      }

      .price {
        position: absolute;
        bottom: 10px;
      }

      @media (max-width: $screen-xs-max) {
        padding: 13px;

        .meta, .title {
          background: #F1F1F1;
          margin-left: -13px;
          margin-right: -13px;
          padding-left: 13px;
          padding-right: 13px;
        }
        .title {
          font-size: 14px;
        }
        .teaser {
          font-size: 12px;
        }
        .teaser {
          font-size: 12px;
        }
      }
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: $screen-sm-min) {
    a:hover .link {
      opacity: 1;
    }
  }

}


.caption-blocks .image {
  background-size: contain;
}
.category-depth-0.category-blocks-img,
.category-depth-1.category-blocks-img,
.category-depth-0.category-blocks-jts,
.category-depth-1.category-blocks-jts {
  .image {
    background-size: cover;
  }
}


.corner {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  height: 60px;
  width: 60px;
  background: linear-gradient(135deg, $primary 49%,rgba(250,223,0,0) 50%,rgba(0,0,0,0) 99%);
  .corner-text {
    transform: rotate(-45deg);
    color: #fff;
    font-weight: bold;
    font-size: 12px;
  }
}


@media (min-width: $screen-sm-min) {

  .filter-open {
    .banner-category .caption {
      bottom: auto !important;
      top: 100px !important;
    }
    .breadcrumb-container {
      margin-top: -330px;
      z-index: 100;
      position: relative;
    }
    .filter {
      z-index: 100;
      position: relative;
    }
    .product-blocks-header {
      padding-top: 25px + 43px;
    }
  }

}

@media (max-width: $screen-xs-max) {


  .filter {
    margin: 13px;
    padding: 15px;
    margin-bottom: 0;

    h3, .h3 {
      background: $primary;
      color: #fff;
      padding: 10px;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -14px;
    }
  }

}

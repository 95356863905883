
// standard
.griditem {

  @media (max-width: 480px) {
    width: 100%;
  }

}

// zubehör
.about-panel .griditem {

  @media (max-width: $screen-xs-max) {
    .item {
      min-height: 50px !important;
      height: auto !important;
      margin-bottom: 2px !important;
      transition: none !important;

      &:before {
        display: block;
        border: none;
        width: 16px;
        height: 16px;
        font-size: 16px;
        position: relative;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        font-family: 'Glyphicons Halflings';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e258";
        float: right;
        margin-top: 22px;
        margin-right: 8px;
        color: #999;
      }
    }
    .title {
      background: none !important;
      font-size: 15px;
      margin: 0;
      padding: 0;
    }
    .price {
      font-size: 12px;
    }
    .details {
      min-height: 40px !important;
      margin-left: 64px !important;
      padding: 13px 0 !important;
    }
    .image {
      width: 40px !important;
      height: 40px !important;
      float: left !important;
      padding: 0 !important;
      margin-top: 8px !important;
      margin-left: 8px !important;
    }
    .meta, .teaser, .link {
      display: none !important;
    }
    .price {
      position: static !important;
      bottom: initial !important;
    }
  }
}

.jts .about-panel .griditem {
  @media (max-width: $screen-xs-max) {
    .image { margin-top: 18px!important }
    .item:before { margin-top: 31px!important }
  }
}

.img .about-panel .griditem {
  @media (max-width: $screen-xs-max) {
    .image { margin-top: 15px!important }
    .item:before { margin-top: 28px!important }
  }
}

.monacor .about-panel .griditem {
  @media (max-width: $screen-xs-max) {
    .details { margin-left: 60px!important }
  }
}


.search-others {
  .product-blocks-items {
    padding-top: 25px;
  }
}
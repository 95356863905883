.contactform {
  .step1, .step2 {
    padding: 25px 35px;
    @media (max-width: $screen-xs-max) {
      padding: 25px 15px;
    }
  }
}

.contact-header {
  a.btn {
    text-transform: none;
    text-decoration: none;
  }
}

.contactform-close {
  margin-right: -35px;
  background: #00549C;
  @media (max-width: $screen-xs-max) {
    margin-right: -15px;
  }
}

.contact-table, .contact-header {
  padding: 25px 25px !important;
  @media (max-width: $screen-xs-max) {
    padding: 25px 15px !important;
  }
}
.contact-table > .row {
  margin-bottom: 25px;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
    margin-top: 25px;
    border-top: 1px solid #eee;
    padding-top: 25px;
  }
}


@media (max-width: $screen-xs-max) {
  .contact-table b.country {
    margin-left: -15px;
  }
  .contact-table .name {
    margin-left: -53px;
    padding-top: 12px;
  }
}




.news-img-wrap .image-caption {
  display: none;
}
.news-img-thumbnails  {
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
}
.news-img-thumbnails a {
  display: block;
  width: 120px;
  text-align: center;
  margin-right: 10px;
}




.news-archive {
  margin-bottom: 80px !important;

  a, time {
    font-weight: bold;
    text-decoration: none;
  }
  li {
    line-height: 1.1;
    margin-bottom: 15px;
  }
  small {
    font-size: 80%;
    display: inline-block;
    line-height: 1.5;
    padding-top: 5px;
    color: rgb(98, 98, 98);
  }
  @media (min-width: 767px) {
    width: 75%;
  }
}



a.btn {
  text-decoration: none;
}

.archive {
  ol {
    padding-bottom: 25px;
  }
  li {
    display: block;
    padding: 10px 15px;
    background: #f2f2f3;
    margin-bottom: 5px;
  }
  .no-news {
    padding: 10px 15px;
    background: #f2f2f3;
    margin-bottom: 5px;
  }
}

.jts .archive .no-news {
  background: #333333;
}

.jts .archive li {
  background: #333333;
}


/**************************************/
/* Default styles for news extension */
/**************************************/
.news .clear {
  clear: both;
}

.news .nav ul {
  float: left;
  display: inline;
  margin: 0;
}

.news .nav li {
  float: left;
}

.news .nav a {
  float: left;
  display: block;
}

.news .nav:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}



.news-list-view {
  ul {
    display: flex;
    flex-flow: row wrap;
    margin-left: -10px;
    margin-right: -20px;
  }
  li {
    width: 33%;
    padding: 10px;
  }
}


.news-list-view a {
  display: block;
  border: 1px solid #ddd;
  background: #fff;
  height: 100% !important;
}

.news-list-view .article {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.news-list-view .article .news-img-wrap,
.news-list-view .article .news-item-content {
  width: 100%;
}
.news-list-view .article .news-img-wrap {
  height: 40%;
  overflow: hidden;
}
.news-list-view .article .news-item-content {
  height: 60%;
}






.news .article .news-img-wrap img {
  float: left;
  height: auto;
  width: auto;
}

.news-list-view .article .news-img-wrap img {
  min-width: 100%;
}

.news .article .teaser-text {
  margin: 0 0 10px 0;
}

.news .article h3 {
  margin-bottom: 0;
}

.news .footer {
  clear: both;
  padding: 10px 0 0 0;
  border-top: 1px solid #eee;
  margin-top: 15px;
  color: #888;
}

.news .footer p {
  line-height: 1em;
}

.news .footer span {
  display: inline-block;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #ddd;
}

.news .footer span:last-child {
  border-right: 0;
}

.news .footer .news-category a {
  text-transform: uppercase;
}

.news .no-news-found {
  color: #DB0202;
  font-style: italic;
}

.news .page-navigation p {
  float: left;
}

.news .page-navigation ul {
  float: right;
}

.news .page-navigation ul li {
  float: left;
  display: block;
  padding: 0 2px;
  background: none;
}

.news .page-navigation ul li a {
  display: block;
}

/* category menu view */
.news-category-view ul li {
  padding: 5px 8px;
}

.news-category-view ul li li {
  padding: 2px 8px 2px 12px;
}

.news-category-view > ul > li:nth-child(even) {
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.news-category-view .category-desc {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
}

/* single view */
.news-single .footer {
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 10px 0 0 0;
  border-bottom: 0;
  border-top: 1px solid #eee;
}

.news-single .article .news-img-wrap {
  float: right;
  width: 282px;
  margin: 0 0 0 25px;
}

.news-single .article .news-img-wrap img {
  float: none;
}

.news-single .teaser-text {
  font-size: 15px;
  color: #888;
}

.news-text-wrap {
  margin-bottom: 10px;
}

.news-img-caption {
  color: #888;
}

/* related news + files */
.news-related-wrap {
  display: table;
  margin-top: 20px;
  width: 100%;
}

.news-related {
  width: 50%;
  padding: 15px;
  border: 1px solid #eee;
  display: table-cell;
}

.news-related ul li {
  line-height: 1em;
  margin-bottom: 12px;
}

.news-related ul li span {
  border-right: 1px solid #DDDDDD;
  display: inline-block;
  margin-right: 8px;
  padding-right: 8px;
  color: #888;
}

.news-related ul li span:last-child {
  border-right: 0;
  padding-right: 0;
}

.news-related h4 {
  margin-bottom: 10px;
}

.news-related-files ul li {
  margin-bottom: 20px;
}

.news-related-files .news-related-files-link .jpg {
  background-position: 0 0;
}

.news-clear {
  clear: both;
}
.textbox-50 {
  background: #CCDDEB;
  color: #145DA3;
  margin-top: -1px;
}
.textbox-50-image {
  background: #E5EEF5
}

.textbox-33 .row {
  margin: 0 -1px 0 0;
}

.textbox-33 .row > div {
  padding: 0;
}

.textblock {
  &.textblock-padtop {
    .section { padding-top: 50px !important };
  }
  &.textblock-padbot {
    .section { padding-bottom: 50px !important };
  }
}

.text-col {
  column-count: 2;
  column-gap: 25px;

  @media (max-width: $screen-xs-max) {
    column-count: 1;
  }
}

.text-block {
  width: 66%;
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}

.img .textblock {
  padding: 25px 0
}
.monacor .textblock .text {
  padding-bottom: 25px;
}

.jts .textblock-header {
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }
}

.jts .textblock-body {
  color: #000;
  background: #efefef;

  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: #000 !important;
  }

}

.jts .textblock-dark {
  background: #262626;

  .textblock-header  {
    padding-bottom: 0;
  }

  .textblock-body {
    background: transparent;
    color: #fff;
  }
}

.blog {

  width: 66%;

  @media (max-width: $screen-sm-max) {
    width: 100%;
  }

  margin-top: 25px;

  .blog-item {
    margin-bottom: 25px;
  }

  .blog-title {
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: normal;
    a { text-decoration: none; }
  }

  .blog-date {
    background: $primary;
    color: #fff;
    float: left;
    width: 50px;
    text-align: center;
    margin-top: 4px;
    border-radius: 4px;

    span {
      display: block;
      line-height: 1;
    }

    .d {
      font-size: 150%;
      font-weight: bold;
      padding: 4px 0;
    }
    .m {
      font-size: 90%;
      text-transform: uppercase;
      padding-bottom: 2px;
    }
    .y {
      font-size: 75%;
      padding-bottom: 4px;
    }
  }

  .blog-content {
    margin-left: 65px;
  }

  .blog-entry {
    margin-top: 15px;
  }

  .blog-image {
    img { min-width: 100% }
  }

  .blog-meta {
    color: #aaa;
    .label-info {
      background-color: rgb(0, 84, 156);
    }
  }

  .blog-author, .blog-time {
    font-size: 80%;
  }

  .page-navigation {
    margin-top: 30px;
    padding-top: 10px;
    border-top: 1px solid rgb(235, 235, 236);
    @include clearfix();
  }

}

.blog-article {

  .blog-header {
    margin-bottom: 25px;
  }


  .blog-meta {
    color: #aaa;
    .label-info {
      background-color: rgb(0, 84, 156);
    }
  }

  .blog-author, .blog-time {
    font-size: 80%;
  }

  .blog-backlink {
    margin: 25px 0;
  }

  .blog-related {
    margin-bottom: 20px;

    h4 {
      font-size: 17px;
    }

    ul {
      padding-left: 16px;
    }

    li {
      list-style-type: square;
    }
  }

  .news-img-wrap .image {
    margin-top: 25px;
    padding-top: 15px !important;
    background: rgb(242, 242, 243) !important;
  }

  .news-img-wrap a {
    background: rgb(242, 242, 243) !important;
  }

  .news-img-wrap .thumbnails a {
    border: 1px solid rgb(221, 221, 221) !important;
  }
  .news-img-wrap .thumbnails a.active {
    border: 1px solid $primary !important;
  }
}

.blog-social {
  margin-bottom: 25px;

  .theme-grey .shariff-button a {
    background: $primary;
  }

  .theme-grey .shariff-button a:hover .share_text {
    text-decoration: underline;
  }
}

.comments {
  .typo3-messages {
    list-style: none;
    padding: 0;

  }
  .typo3-messages li {
    list-style: none;
  }
}

.comment-text {
  padding-top: 10px;
}

.comments-list {
  .comment {
    time {
      font-size: 90%;
    }

    margin-bottom: 25px;
  }
}



.active-comment-form {
  padding: 25px 0 10px 0;
  background: rgb(242, 242, 243);
  margin-bottom: 15px;

  #newsCommentSubmit {
    margin-bottom: 10px;
  }
}

.monacor-retailer-landingpage {
  padding: 40px 0 48px;

  header {
    margin-bottom: 48px;
    address {
      ul {
        padding: 0;
        list-style: none;
        &:after, &before {
          content: '';
          display: table;
          clear: both;
        }
        li {
          float: left;
          + li {
            margin-left: 10px;
            padding-left: 10px;
            position: relative;
            &:before {
              left: 0;
              height: 100%;
              content: ' ';
              border-left: 1px solid $text-color;
              display: inline-block;
              position: absolute;
            }
          }
        }
      }
    }
    .contact {
      ._contact {
        margin-bottom: 1em;
        b {
          display: block;
        }
      }
      ._phone {
      }
      ._mobile {
      }
      ._fax {
      }
      ._email {

      }
      ._zeiten {
      }
      ._www {
        margin-top: 1em;
        .btn {
          text-decoration: none;
        }
      }
    }
    .landingpage-logo-wrap {
      text-align: right;
      width: 100%;
      img {

      }
    }
  }

  section {
    margin-bottom: 12px;
    .description-headline, .reference-headline {
      line-height: 2;
    }
    .description-text, .reference-text {
    }
    .description-headline {
    }
    .description-text {
      margin-bottom: 48px;
    }
    .referencr-wrap {
      margin-bottom: 48px;
      @include box-shadow(0px 1px 5px 0px rgba(0, 0, 0, 0.2));
      .reference-headline {
        padding: 10px 10px 10px 10px;
      }
      .reference-text {
        padding: 0 10px 10px 10px;
      }
    }
  }
  .product-blocks-items {
    margin-top: 50px;
    background: #FFFFFF;

    .item {
      min-height: 340px;
      .image {
        padding: 30px 45px;
        height: 200px;
        text-align: center;
        img {
          padding: 0;
        }
      }
      .details {
        min-height: 140px;
        padding-top: 0;
        padding-bottom: 100px;
        .price {
          bottom: 65px;
        }
      }
    }
    .link {
      opacity: 1;
    }
  }
  @media (max-width: $screen-sm-max) {
    .product-blocks-items {
      margin-top: 0;
      .item {
        .details {
          min-height: 240px;
        }
      }
    }
    header {
      address {
        ul {
          padding: 0;
          list-style: none;
          &:after, &before {
            content: '';
            display: table;
            clear: both;
          }
          li {
            float: none;
            + li {
              margin-left: 0;
              padding-left: 0;
              position: relative;
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }

  }
  @media (max-width: $screen-xs-max) {
    .product-blocks-items {
      padding: 0;
      .item {
        min-height: 0;
        .details {
          min-height: 0;
        }
      }
    }
  }

  form {
    input[type=checkbox] {
      display: inline-block;
    }
    .checkbox .at-required-highlight {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

// Abweichende Styles Marken
.jts {
  .monacor-retailer-landingpage {
    background: #E6E6E6;
    color: #000;
    .product-blocks-items {
      background: transparent;
    }
  }
}
// TODO: IMG-STAGELINE

/*Retailer Link*/
#map_overlay {
  .landingpage-wrap {
    margin-top: 35px;
    .landingpage {
      text-decoration: none;
    }
  }
  .productteaser.product-blocks-items {
    float: right;
    max-width: 40%;
    background: transparent;
    > div {
      + div {
      }
      a.item {
        &:hover {
          transform: scale(1.0);
        }
        min-height: 0;
        .details {
          .title {
            font-size: 1.5em;
            @media screen and (max-width: 767px) {
              font-size: 1.2em;
            }
          }
          .meta {
          }
          .teaser {
          }
          .price {
            bottom: 15px;
          }
        }
        .link {
        }
      }
    }
  }
}

#map_result_items {
  .item {
    .landingpage {
      margin-top: 10px;
      text-decoration: none;
      display: none;
      width: 100%;
    }
    &:hover .landingpage {
      background: transparent;
      border-color: #FFFFFF;
      color: #FFFFFF;
    }
  }
}
